import { uniq } from 'lodash';
import React, { Fragment, useState } from 'react';

import type { Occupation, User } from 'models';

import can from 'helpers/can';
import {
  dateWithoutTimeFromDateString,
  startToEndDateInMonths,
  timeBetweenDates,
} from 'helpers/date';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { del } from 'redux/actions/api';

import {
  Button,
  Hoverable,
  Icon,
  StrictlySanitizedHtml,
  Text,
} from 'components';

import SalaryHistory from 'scenes/components/professionalBackground/SalaryHistory';
import addProgressPercent from 'scenes/components/professionalBackground/helpers/addProgressPercent';
import groupConsecutiveSimilarOccupations from 'scenes/components/professionalBackground/helpers/groupConsecutiveSimilarOccupations';

import OccupationManagementModal from './OccupationManagementModal';
import { SideBarLine, SideBarRound } from './sidebar';

type Props = {
  user: User;
  occupations: Array<Occupation>;
  blurSalary: boolean;
  refetchOccupations: () => void;
};

const OccupationHistory = ({
  user,
  occupations,
  blurSalary,
  refetchOccupations,
}: Props) => {
  const groupedOccupations = groupConsecutiveSimilarOccupations(
    addProgressPercent(occupations)
  );

  const [updateModalActiveForOccupation, setUpdateModalActiveForOccupation] =
    useState<Occupation | null>(null);

  const canManageProfessionalBackground = can({
    perform: 'manage_professional_background',
    on: user,
  });

  const dispatch = useAppDispatch();

  const destroyOccupation = async (occupation: Occupation) => {
    return confirmAsync(
      __('Delete the job?'),
      <StrictlySanitizedHtml
        html={__(
          'This action will delete the <b>%1</b> job.<br/>It will no longer be visible in the employee’s professional background, and the remuneration informations for this job will be deleted.',
          occupation.title
        )}
      />,
      {
        isDanger: true,
        confirmLabel: __('Delete the job'),
        onConfirm: async () => {
          await dispatch(
            del(`users/${user.id}/occupations/${occupation.id}`, {})
          );

          refetchOccupations();
        },
      }
    );
  };

  const actionsForOccupation = (occupation: Occupation) =>
    !canManageProfessionalBackground ? null : (
      <Fragment>
        <div>
          <Button
            hasIconOnly
            className="border-none bg-transparent"
            onClick={() => setUpdateModalActiveForOccupation(occupation)}
          >
            <Icon name="edit" />
          </Button>
          <Button
            hasIconOnly
            className="border-none bg-transparent"
            onClick={() => destroyOccupation(occupation)}
          >
            <Icon name="delete" />
          </Button>
        </div>
      </Fragment>
    );

  return (
    <Fragment>
      <ul className="mt-4">
        {groupedOccupations.map(occupationGroup => {
          const mostRecentEndDate = occupationGroup.endDate;
          const oldestStartDate = dateWithoutTimeFromDateString(
            occupationGroup.startDate
          );
          const humanizedPeriod = startToEndDateInMonths(
            oldestStartDate,
            !!mostRecentEndDate
              ? dateWithoutTimeFromDateString(mostRecentEndDate)
              : undefined
          );
          const humanizedTimeSinceStartDate = timeBetweenDates(
            oldestStartDate,
            !!mostRecentEndDate
              ? dateWithoutTimeFromDateString(mostRecentEndDate)
              : new Date()
          );

          const anyOccupationWithSalaryInfo = occupationGroup.occupations.some(
            occupation =>
              !!occupation.fixedRemunerationCents ||
              !!occupation.variableRemunerationCents ||
              !!occupation.remunerations?.length
          );

          const buildBlock = () => (
            <li
              key={occupationGroup.title + occupationGroup.startDate}
              className="mt-2"
            >
              <div className="flex">
                <SideBarRound />
                <div className="flex items-center justify-between w-full h-6">
                  <Text preset="14bs6" className="block mb-0 ">
                    {occupationGroup.title}
                  </Text>
                </div>
              </div>
              <div className="flex">
                <SideBarLine />
                <div className="flex-1">
                  <Text color="light">
                    {humanizedPeriod.charAt(0).toUpperCase() +
                      humanizedPeriod.slice(1)}
                    {' • '}
                    {humanizedTimeSinceStartDate}
                  </Text>

                  {anyOccupationWithSalaryInfo && (
                    <SalaryHistory
                      actionsForOccupation={actionsForOccupation}
                      occupations={occupationGroup.occupations}
                      blurSalary={blurSalary}
                    />
                  )}
                </div>
              </div>
            </li>
          );

          if (!anyOccupationWithSalaryInfo) {
            return (
              <Hoverable
                render={hovered => (
                  <div className="flex justify-between items-center">
                    {buildBlock()}
                    {hovered &&
                      actionsForOccupation(occupationGroup.occupations[0])}
                  </div>
                )}
              />
            );
          }
          return buildBlock();
        })}
      </ul>

      {!!updateModalActiveForOccupation && (
        <OccupationManagementModal
          user={user}
          occupation={updateModalActiveForOccupation}
          existingOccupationTitles={uniq(
            occupations.map(occupation => occupation.title)
          ).sort()}
          refetchOccupations={refetchOccupations}
          onClose={() => setUpdateModalActiveForOccupation(null)}
        />
      )}
    </Fragment>
  );
};

export default OccupationHistory;
