import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { __ } from 'helpers/i18n';

import { Text, TooltipOnEllipsis } from 'components';

import { Data } from './types';

type LegendItemProps = {
  data: Data;
  compact?: boolean;
  valuePositionInLegend?: 'top' | 'bottom' | 'right' | 'hidden';
  formatValue?: (value: number | null) => ReactNode;
  formatLegend?: (data: Data) => ReactNode;
  className?: string;
};

const LegendItem = ({
  data,
  compact,
  valuePositionInLegend,
  formatValue,
  formatLegend,
  className,
}: LegendItemProps) => {
  if (formatLegend) {
    const formatedLegend = formatLegend(data);
    if (formatedLegend !== undefined)
      return <div className={className}>{formatedLegend}</div>;
  }

  const { value, color, label, name } = data;
  const gapClassName = compact ? 'gap-1' : 'gap-2';

  const renderValue = () => {
    if (valuePositionInLegend === 'hidden') return null;

    if (formatValue) {
      const formatedValue = formatValue(value);
      if (formatedValue !== undefined) return formatedValue;
    }

    return (
      <Text preset="24bs2" testClassName={`test-flat-pie-chart-${name}`}>
        {value === null ? '-' : value}%
      </Text>
    );
  };

  const renderLabel = () => (
    <Text
      preset="14s6"
      className="whitespace-nowrap overflow-hidden text-ellipsis"
    >
      <TooltipOnEllipsis>
        <span
          className="h-2 w-2 inline-block rounded-sm mr-2"
          style={{ backgroundColor: color }}
        />
        <Text preset="14s6">
          {label}
          {valuePositionInLegend === 'right' && __(': ')}
        </Text>
      </TooltipOnEllipsis>
    </Text>
  );

  const getFlexClassName = () => {
    switch (valuePositionInLegend) {
      case 'top':
        return 'flex flex-col-reverse';
      case 'right':
        return 'flex items-center flex-row';
      case 'bottom':
      default:
        return 'flex flex-col';
    }
  };

  return (
    <div className={classNames(getFlexClassName(), gapClassName, className)}>
      {renderLabel()}
      {renderValue()}
    </div>
  );
};

export default LegendItem;
