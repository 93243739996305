import React from 'react';
import { type RouteComponentProps, useRouteMatch } from 'react-router-dom';

import { useCurrentOrganization } from 'helpers/hooks';
import { pathToValidatedTrainingPlan } from 'helpers/paths';

import { Redirect, Route, Switch } from 'components';

import SimbelTrainingRoutes from 'scenes/components/simbel/SimbelTrainingRoutes';

import Budget from './Budget';
import Landing from './Landing';
import PeriodCreator from './PeriodCreator';
import PeriodRedirector from './PeriodRedirector';
import PeriodRequests from './PeriodRequests';
import Plan from './Plan';
import Session from './Session';

const Routes = (props: RouteComponentProps) => {
  const { path } = useRouteMatch();
  const organization = useCurrentOrganization();

  if (organization.isSimbelIntegrationActive) {
    return <SimbelTrainingRoutes {...props} />;
  }

  const trainingPlansEnabled =
    organization.featureFlags.includes('trainingPlans');
  const budgetEnvelopesEnabled =
    organization.featureFlags.includes('budgetEnvelopes');

  return (
    <Switch>
      <Route exact path={`${path}`} component={PeriodRedirector} />
      <Route path={`${path}/sessions/:id`} component={Session} />
      <Route path={`${path}/periods/new`} component={PeriodCreator} />
      <Route path={`${path}/landing`} component={Landing} />
      <Route path={`${path}/:periodSlug/requests`} component={PeriodRequests} />
      {budgetEnvelopesEnabled && (
        <Route
          path={`${path}/:periodSlug/budget`}
          key="budget"
          component={Budget}
        />
      )}
      {trainingPlansEnabled
        ? [
            <Route
              path={`${path}/:periodSlug/forecast`}
              key="forecast"
              render={props => <Plan isValidatedPlan={false} {...props} />}
            />,
            <Route
              path={`${path}/:periodSlug/validated`}
              key="validated"
              render={props => <Plan isValidatedPlan {...props} />}
            />,
            <Route
              path={`${path}/:periodSlug/sessions`}
              key="sessions"
              render={({ match }) => {
                const periodSlug = match.params.periodSlug;
                return (
                  <Switch>
                    <Redirect to={pathToValidatedTrainingPlan(periodSlug)} />
                  </Switch>
                );
              }}
            />,
          ]
        : [
            <Route
              path={`${path}/:periodSlug/sessions`}
              key="sessions"
              render={props => <Plan isValidatedPlan {...props} />}
            />,
          ]}
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default Routes;
