import { capitalize } from 'lodash';
import React from 'react';

import type {
  MatrixStatus,
  PeopleReviewCycleStatus,
  ReviewCycleStatus,
  SurveyCampaignStatus,
  TrainingCourseStatus,
  TrainingSessionStatus,
} from 'models';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import { Icon, Text } from 'components';

import sessionStatusLabels from 'scenes/trainings/PlanManagement/Session/HeaderActions/sessionStatusLabels';

type Props = {
  status:
    | SurveyCampaignStatus
    | ReviewCycleStatus
    | PeopleReviewCycleStatus
    | MatrixStatus
    | TrainingSessionStatus
    | TrainingCourseStatus;
  className?: string;
};

export const trainingSessionStatusIcons = {
  in_preparation: 'edit' as const,
  prepared: 'timer' as const,
  completed: 'check_circle' as const,
  canceled: 'cancel' as const,
  rescheduled: 'event_upcoming' as const,
};

const StatusTag = ({ status, className }: Props) => {
  const tagColor =
    {
      // Common
      draft: 'light' as const,
      launching: 'dark' as const,
      ended: 'success' as const,
      published: 'success' as const,
      unpublished: 'light' as const,
      // Review cycle & people review cycle
      active: 'blue' as const,
      // Review cycle
      failed: 'danger' as const,
      // Survey
      scheduled: 'dark' as const,
      in_progress: 'blue' as const,
      archived: 'grey' as const,
      inactive: 'warning' as const,
      // People review cycle
      finalized: 'success' as const,
      // Training session
      in_preparation: 'light' as const,
      prepared: 'warning' as const,
      completed: 'success' as const,
      canceled: 'danger' as const,
      rescheduled: 'danger' as const,
    }[status] || 'light';

  const statusText =
    {
      // Common
      draft: __('status_draft'),
      launching: __('status_launching'),
      ended: __('status_ended'),
      published: __('status_published'),
      unpublished: __('status_unpublished'),
      // Review cycle & people review cycle
      active: __('status_active'),
      // Review cycle
      failed: __('status_failed'),
      // Survey
      scheduled: __('status_scheduled'),
      in_progress: __('status_in_progress'),
      archived: __('status_archived'),
      inactive: __('status_inactive'),
      // People review cycle
      finalized: __('status_finalized'),
      // Training session
      ...sessionStatusLabels(),
    }[status] || status;

  const iconName = {
    // Common
    draft: 'edit' as const,
    launching: 'timer' as const,
    ended: 'check_circle' as const,
    published: 'check' as const,
    unpublished: 'visibility_off' as const,

    // Review cycle & people review cycle
    active: 'play_circle' as const,
    // Review cycle
    failed: 'warning' as const,
    // Survey
    scheduled: 'timer' as const,
    in_progress: 'play_circle' as const,
    archived: 'inbox' as const,
    inactive: 'pause_circle' as const,
    // People review cycle
    finalized: 'check_circle' as const,
    // Training session
    ...trainingSessionStatusIcons,
  }[status];

  const isIconFilled = iconName === 'check_circle';

  const colorClassName = {
    light: {
      background: 'bg-text-lightest/30',
      icon: 'text-text-light',
      text: 'text-text-main',
    },
    grey: {
      background: 'bg-text-light/30',
      icon: 'text-text-soften',
      text: 'text-text-main',
    },
    dark: {
      background: 'bg-text-main/60',
      icon: 'text-white',
      text: 'text-white',
    },
    blue: {
      background: 'bg-blue-elevo/30',
      icon: 'text-blue-elevo',
      text: 'text-text-main',
    },
    danger: {
      background: 'bg-red-fuckup/30',
      icon: 'text-red-fuckup',
      text: 'text-text-main',
    },
    warning: {
      background: 'bg-orange-warning/30',
      icon: 'text-yellow-400',
      text: 'text-text-main',
    },
    success: {
      background: 'bg-green-success/30',
      icon: 'text-green-success',
      text: 'text-text-main',
    },
  }[tagColor];

  return (
    <span
      className={classNames(
        'tag is-rounded normal-case max-w-full',
        colorClassName.background,
        className
      )}
    >
      {iconName && (
        <Icon
          style={{ marginRight: 5 }}
          name={iconName}
          filled={isIconFilled || false}
          className={colorClassName.icon}
        />
      )}
      <Text
        preset="14s6"
        className={classNames(
          'text-text-main is-text-overflow-ellipsis',
          colorClassName.text
        )}
        testClassName={`test-survey-status-tag-${status}`}
      >
        {capitalize(statusText)}
      </Text>
    </span>
  );
};

export default StatusTag;
