const aiLogo = `<?xml version="1.0" encoding="UTF-8"?>
<svg id="logo" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 568 201">
  <!-- Generator: Adobe Illustrator 29.1.0, SVG Export Plug-In . SVG Version: 2.1.0 Build 142)  -->
  <defs>
    <style>
      .st0 {
        fill: none;
        stroke: #101010;
        stroke-width: 1.8px;
      }

      .st1 {
        fill: #101010;
      }
    </style>
  </defs>
  <path class="st0" d="M538.2,61c0,18.9-15.4,34.3-34.3,34.3s-34.3-15.4-34.3-34.3,15.4-34.3,34.3-34.3,34.3,15.4,34.3,34.3Z"/>
  <g>
    <path class="st1" d="M514.4,74.2v-28.5h3.7v28.5h-3.7Z"/>
    <path class="st1" d="M485.2,74.2l11.4-28.5h4.3l11.3,28.5h-4l-2.9-7.3h-13.2l-2.9,7.3h-3.9ZM493.2,63.7h10.8l-5.4-13.7-5.4,13.7Z"/>
  </g>
  <path class="st1" d="M72.9,175.8c-9.5,0-17.7-2.1-24.8-6.2-7-4.3-12.5-10.1-16.4-17.5-3.9-7.5-5.9-16-5.9-25.6s1.9-18.2,5.7-25.6c3.9-7.4,9.3-13.2,16.3-17.3,7-4.3,15.2-6.4,24.4-6.4s17.8,2.3,24.8,6.8c7,4.5,12.2,10.6,15.6,18.3,3.5,7.7,4.6,16.4,3.3,26.1H41.4c.3,10.6,3.3,19,9,25.1,5.7,6,13.2,9,22.5,9s14.1-1.7,18.7-5.2c4.7-3.5,8.2-8,9.3-13.5h15.3c-.4,6.9-5.9,17-13.3,23-7.4,6-18,9-30,9ZM42.2,116.1h58c-.1-7.8-2.8-14.1-8-18.7-5.1-4.7-11.7-7.1-19.9-7.1s-14.6,2.3-19.9,6.8c-5.2,4.5-8.6,10.8-10.2,19Z"/>
  <path class="st1" d="M240,175.8c-9.5,0-17.7-2.1-24.8-6.2-7-4.3-12.5-10.1-16.4-17.5-3.9-7.5-5.9-16-5.9-25.6s1.9-18.2,5.7-25.6c3.9-7.4,9.3-13.2,16.3-17.3,7-4.3,15.2-6.4,24.4-6.4s17.8,2.3,24.8,6.8c7,4.5,12.2,10.6,15.6,18.3,3.5,7.7,4.6,16.4,3.3,26.1h-74.4c.3,10.6,3.3,19,9,25.1,5.7,6,13.2,9,22.5,9s14.1-1.7,18.7-5.2c4.7-3.5,8.2-8,9.3-13.5h15.3c-.4,6.9-5.9,17-13.3,23-7.4,6-18,9-30,9ZM209.4,116.1h58c-.1-7.8-2.8-14.1-8-18.7-5.1-4.7-11.7-7.1-19.9-7.1s-14.6,2.3-19.9,6.8c-5.2,4.5-8.6,10.8-10.2,19Z"/>
  <path class="st1" d="M323.9,175.8l-36.5-98.5h16.6l28.2,78.8,28-78.8h16.1l-36.7,98.5h-15.8Z"/>
  <path class="st1" d="M127.9,175.8v-12.8h19.2v-98h-15.5v-12.6h30.9v110.6h18.9v12.8h-53.5Z"/>
  <path class="st1" d="M427.4,175.8c-9,0-17.1-2.1-24.2-6.2-7.2-4.2-12.8-9.9-17-17.3-4-7.5-6.1-16.1-6.1-25.8s2-18.4,6.1-25.8c4.2-7.4,9.8-13.2,17-17.3,7.2-4.2,15.2-6.2,24.2-6.2s17.3,2.1,24.4,6.2c7.2,4.2,12.8,9.9,16.8,17.3,4.2,7.4,6.2,16,6.2,25.8s-2.1,18.3-6.2,25.8c-4,7.4-9.6,13.2-16.8,17.3-7.2,4.2-15.3,6.2-24.4,6.2ZM427.4,162.3c6.2,0,11.7-1.5,16.4-4.5,4.8-3.1,8.6-7.3,11.3-12.6,2.8-5.4,4.2-11.7,4.2-18.7s-1.4-13.3-4.2-18.7c-2.7-5.4-6.4-9.6-11.3-12.6-4.7-3.1-10.2-4.7-16.4-4.7s-11.5,1.6-16.3,4.7c-4.7,3-8.5,7.2-11.3,12.6-2.7,5.4-4,11.7-4,18.7s1.3,13.3,4,18.7c2.8,5.3,6.5,9.5,11.3,12.6,4.7,3,10.2,4.5,16.3,4.5Z"/>
</svg>
`;
export default aiLogo;
