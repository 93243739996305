import React, { useCallback, useContext } from 'react';
import { compose } from 'redux';

import { AdminCollection } from 'models/User';

import compositeKey from 'helpers/compositeKey';
import { __, date, n__ } from 'helpers/i18n';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { PaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  EmptyState,
  FetchContainer,
  SimpleTable,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

import { DataContext } from '..';
import ItemMenu from './ItemMenu';
import RolesOverview from './RolesOverview';

type AfterDataLoaderProps = PaginationProps &
  DataLoaderProvidedProps & { adminCollection: AdminCollection };

const AdminList = ({
  adminCollection,
  isFetching,
  hasError,
  refetchData,
  page,
  countPerPage,
  queryParams: { search, userFilters },
  setNextPageParams,
  setPreviousPageParams,
  setQueryParams,
}: AfterDataLoaderProps) => {
  const { shouldRefetchAdmins, setShouldRefetchAdmins } =
    useContext(DataContext);

  const refetchAdmins = useCallback(() => {
    refetchData();
    setShouldRefetchAdmins(false);
  }, [refetchData, setShouldRefetchAdmins]);

  React.useEffect(() => {
    if (shouldRefetchAdmins) refetchAdmins();
  }, [refetchAdmins, shouldRefetchAdmins]);

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={adminCollection}
        isFetching={isFetching}
        hasError={hasError}
        page={page}
        countPerPage={countPerPage}
        search={search}
        getNextPage={setNextPageParams}
        getPreviousPage={setPreviousPageParams}
        onQueryParamsChange={setQueryParams}
        withSearch
        withUserMultiFilters
        userFilters={userFilters}
        renderNoResult={() => (
          <EmptyState title={__('No admin matches your search')} inBoxList />
        )}
        totalCountRenderer={totalCount =>
          n__('%1 user', '%1 users', totalCount || 0)
        }
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <SimpleTable
              keyFn={user => user.id}
              rows={adminCollection?.users || []}
              columns={[
                {
                  header: __('Users'),
                  cell: user => <UserAvatar user={user} withJobTitle />,
                },
                {
                  header: __('Roles & entities'),
                  cell: user => <RolesOverview user={user} />,
                },
                {
                  header: __('Last update'),
                  cell: user => date(user.lastAdminRoleUpdatedAt, 'LL'),
                },
                {
                  header: '',
                  cell: user => (
                    <ItemMenu user={user} refetchData={refetchData} />
                  ),
                  isNarrow: true,
                },
              ]}
            />
          )}
        />
      </DatatableWrapper>
    </Box>
  );
};

export default compose<React.ComponentType>(
  withPagination,
  newDataLoader({
    fetch: ({ page, countPerPage, queryParams }) => {
      const { search, userFilters } = queryParams || {};
      return get('users/admins', {
        page,
        countPerPage,
        search,
        userFilters,
      });
    },
    hydrate: {
      adminCollection: {
        users: {
          roles: { resource: {} },
        },
      },
    },
    cacheKey: ({ page, countPerPage, queryParams: { search, userFilters } }) =>
      compositeKey({ page, countPerPage, search, userFilters }),
  })
)(AdminList);
