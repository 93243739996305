import type { CompensationRemuneration, Occupation } from 'models';

const computeRemunerationPercentChange = (
  value1: number | null | undefined,
  value2: number | null | undefined
): number | null =>
  !!value1 && !!value2 ? ((value2 - value1) / value1) * 100 : null;

const addRemunerationProgressPercent = (
  occupations: Array<Occupation>,
  index: number
): Array<CompensationRemuneration> | undefined => {
  const occupation = occupations[index];
  const previousOccupation = index === 0 ? occupation : occupations[index - 1];

  const remunerations = occupation.remunerations?.map(remuneration => {
    const previousRemuneration = previousOccupation.remunerations?.find(
      previousRemuneration =>
        previousRemuneration.remunerationTypeId ===
        remuneration.remunerationTypeId
    );
    const percentChange =
      previousRemuneration?.valueCurrency === remuneration.valueCurrency
        ? computeRemunerationPercentChange(
            previousRemuneration?.valueCents,
            remuneration.valueCents
          )
        : null;
    return { ...remuneration, percentChange };
  });
  return remunerations;
};

export default addRemunerationProgressPercent;
