import React from 'react';
import { compose } from 'redux';

import type { DeprecatedPaginationProps } from 'lib/dataLoader/pagination/types';

import { MessagingMessage, PaginatedCollection } from 'models';

import compositeKey from 'helpers/compositeKey';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import withDeprecatedStatePagination from 'lib/dataLoader/pagination/DeprecatedStatePaginationFactory';
import { get } from 'redux/actions/api';

import { InfiniteFetchContainer } from 'components';

import MessageBox from './MessageBox';

type Props = {
  messagingChatRoomId: string | null;
  sentMessagesCount?: number;
};

type AfterPaginateProps = Props & DeprecatedPaginationProps;

type AfterDataLoaderProps = Props &
  DeprecatedPaginationProps &
  DataLoaderProvidedProps & {
    messagingMessagesCollection: PaginatedCollection<MessagingMessage>;
  };

const ChatRoomConversation = ({
  messagingMessagesCollection,
  isFetching,
  hasError,
  getNextPage,
}: AfterDataLoaderProps) => {
  return (
    <InfiniteFetchContainer
      collection={messagingMessagesCollection}
      getNextPage={getNextPage}
      isFetching={isFetching}
      hasError={hasError}
      render={messages =>
        messages.map(message => (
          <MessageBox
            key={message.id}
            message={message.content}
            userAvatarUrl={message.user.avatarUrl}
            userName={message.user.fullName}
            alignAvatar={message.user.id === 'anonymous' ? 'left' : 'right'}
          />
        ))
      }
    />
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedStatePagination({
    resetPageFor: ({ sentMessagesCount }: Props) => [sentMessagesCount],
  }),
  newDataLoader({
    fetch: ({ messagingChatRoomId, page, countPerPage }: AfterPaginateProps) =>
      get(`survey/messaging/chat_rooms/${messagingChatRoomId}/messages`, {
        page,
        countPerPage,
      }),
    hydrate: {
      messagingMessagesCollection: {
        items: { user: {} },
      },
    },
    cacheKey: ({ page, countPerPage, sentMessagesCount }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, sentMessagesCount }),
  })
)(ChatRoomConversation);
