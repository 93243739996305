import { orderBy } from 'lodash';
import React from 'react';

import type { ReviewCycle } from 'models';

import { DesignSystem } from 'components';

import {
  OneOnOneReviewCycleItem,
  ThreeSixtyReviewCycleItem,
} from 'scenes/components/reviewCycle/ListItem';
import UserReviewsPaginatedList from 'scenes/reviews/components/UserReviewsPaginatedList';

type Props = {
  reviewCycles: Array<ReviewCycle>;
  testClassName: string;
  reloadList: () => Promise<void>;
};

const ReviewCycleList = ({
  reviewCycles,
  testClassName,
  reloadList,
}: Props) => {
  const sortedReviewCycles = orderBy(reviewCycles, 'launchedAt', 'desc');

  return (
    <div className={testClassName}>
      <DesignSystem version={2}>
        {sortedReviewCycles.map((reviewCycle, index) => {
          // queue the user review loading queries to avoid potential ddos issues
          const initialQueryMsDelay = 200 * index;
          const paginatedUserReviewsList = (
            <UserReviewsPaginatedList
              reviewCycle={reviewCycle}
              reloadList={reloadList}
              initialQueryMsDelay={initialQueryMsDelay}
              endpoint={'other_user_reviews'}
            />
          );

          if (reviewCycle.interactionType === '360') {
            return (
              <ThreeSixtyReviewCycleItem
                key={reviewCycle.id}
                reviewCycle={reviewCycle}
                hasManagerAnalytics={false}
              >
                {paginatedUserReviewsList}
              </ThreeSixtyReviewCycleItem>
            );
          } else {
            return (
              <OneOnOneReviewCycleItem
                key={reviewCycle.id}
                reviewCycle={reviewCycle}
                displayDeadlines={!reviewCycle.autoAddParticipantsEnabled}
                hasManagerAnalytics={false}
              >
                {paginatedUserReviewsList}
              </OneOnOneReviewCycleItem>
            );
          }
        })}
      </DesignSystem>
    </div>
  );
};

export default ReviewCycleList;
