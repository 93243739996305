import { omit } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { DeprecatedPaginationProps } from 'lib/dataLoader/pagination';
import type { WithDeprecatedPaginationProps } from 'lib/dataLoader/pagination/withDeprecatedPagination';
import type { ReviewTemplateCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n/index';

import { withDeprecatedPagination } from 'lib/dataLoader';
import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { Box, BoxList, DatatableWrapper } from 'components';

import ReviewEmptyState from 'scenes/components/userReview/ListEmptyState/index';

import TemplateList from './TemplateList';

type Props = WithDeprecatedPaginationProps & {
  templateType: string | undefined | null;
};

type AfterPaginateProps = Props &
  DeprecatedPaginationProps &
  DataLoaderProvidedProps;

type AfterConnectProps = AfterPaginateProps & {
  templateCollection: ReviewTemplateCollection;
  isFetching: boolean;
  hasError: boolean;
};

function TemplateListWithPagination({
  search,
  page,
  filter,
  countPerPage,
  templateCollection,
  isFetching,
  hasError,
  onSearchChange,
  onFilterChange,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
  refetchData,
}: AfterConnectProps) {
  const templates = templateCollection
    ? templateCollection.reviewTemplates
    : [];

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={
          templateCollection ? omit(templateCollection, 'templates') : null
        }
        totalCountRenderer={(totalCount?: number | null) => {
          return n__(
            '%1 review template',
            '%1 review templates',
            totalCount || 0
          );
        }}
        search={search}
        page={page}
        countPerPage={countPerPage}
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        onSearchChange={onSearchChange}
        onFilterChange={onFilterChange}
        filter={filter}
        searchPlaceholder={__('Search review templates')}
        isFetching={isFetching}
        hasError={hasError}
        renderNoResult={() => (
          <ReviewEmptyState
            title={__('No review template matches your search')}
          />
        )}
      >
        <BoxList>
          <TemplateList
            refetchData={refetchData}
            reviewTemplates={templates}
            isFetching={isFetching}
            hasError={hasError}
          />
        </BoxList>
      </DatatableWrapper>
    </Box>
  );
}

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({
      templateType,
      page,
      countPerPage,
      search,
      filter,
    }: AfterPaginateProps) => {
      if (templateType) {
        return get('review_templates', {
          page,
          countPerPage,
          search,
          filter: templateType ? { [templateType]: true } : filter,
        });
      }

      return Promise.resolve;
    },
    hydrate: {
      templateCollection: {
        reviewTemplates: {},
      },
    },
    cacheKey: ({
      templateType,
      page,
      countPerPage,
      search,
      filter,
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, filter, templateType }),
  })
)(TemplateListWithPagination);
