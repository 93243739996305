import React from 'react';
import { compose } from 'redux';

import type { DeprecatedPaginationProps } from 'lib/dataLoader/pagination/types';
import type { WithDeprecatedPaginationProps } from 'lib/dataLoader/pagination/withDeprecatedPagination';

import {
  DraftSurveyCampaign,
  PaginatedCollection,
  SurveyCampaign,
} from 'models';

import can from 'helpers/can';
import compositeKey from 'helpers/compositeKey';
import { useOrganizationPlan } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import {
  pathToSurveyCampaignEdit,
  pathToSurveyCampaignOverview,
} from 'helpers/paths';

import {
  DataLoaderProvidedProps,
  newDataLoader,
  withDeprecatedPagination,
} from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  EmptyState,
  FetchContainer,
  HamburgerMenu,
  Link,
  MenuList,
  SimpleTable,
} from 'components';

import StatusTag from 'scenes/components/StatusTag';

import ArchiveMenuItem from '../components/ArchiveMenuItem';
import DuplicateMenuItem from '../components/DuplicateMenuItem';
import pollingPeriodInfoByCampaignStatus from './helpers/pollingPeriodInfoByCampaignStatus';

type Props = WithDeprecatedPaginationProps;
type AfterPaginateProps = Props & DeprecatedPaginationProps;
type AfterDataLoaderProps = Props &
  DeprecatedPaginationProps &
  DataLoaderProvidedProps & {
    campaignCollection: PaginatedCollection<
      SurveyCampaign | DraftSurveyCampaign
    >;
  };

const CampaignList = ({
  search,
  page,
  countPerPage,
  campaignCollection,
  isFetching,
  hasError,
  onSearchChange,
  filter,
  onFilterChange,
  sort,
  onSortChange,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
  refetchData,
}: AfterDataLoaderProps) => {
  const campaigns = campaignCollection?.items || [];

  const { surveysEnabled } = useOrganizationPlan();

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={campaignCollection}
        search={search}
        page={page}
        countPerPage={countPerPage}
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        onSearchChange={onSearchChange}
        onFilterChange={onFilterChange}
        filters={[
          { param: 'not_archived', label: __('Not archived') },
          { param: 'archived', label: __('Archived') },
          { param: 'all', label: __('All') },
        ]}
        filter={filter}
        searchPlaceholder={__('Search survey campaigns')}
        isFetching={isFetching}
        hasError={hasError}
        renderNoResult={() => (
          <EmptyState
            title={__('No survey campaign matches your search')}
            inBoxList
          />
        )}
        totalCountRenderer={totalCount =>
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          n__('%1 survey campaign', '%1 survey campaigns', totalCount)
        }
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <SimpleTable
              columns={[
                {
                  header: __('Name'),
                  cell: campaign => (
                    <Link
                      to={
                        campaign.status === 'draft'
                          ? pathToSurveyCampaignEdit(campaign.id)
                          : pathToSurveyCampaignOverview(campaign.id)
                      }
                      testClassName="test-survey-campaign-link"
                    >
                      {campaign.name}
                    </Link>
                  ),
                  activeSort: sort && sort['name'],
                  onSort: () => onSortChange && onSortChange('name'),
                },
                {
                  header: __('Type'),
                  cell: campaign =>
                    campaign.recurrenceType === 'one_off'
                      ? __('One-off')
                      : __('Recurring'),
                },
                {
                  header: __('Status'),
                  cell: campaign => <StatusTag status={campaign.status} />,
                },
                {
                  header: __('Additional information'),
                  cell: campaign => pollingPeriodInfoByCampaignStatus(campaign),
                },
                {
                  header: '',
                  cell: campaign => {
                    const showArchive = can({
                      perform: 'archive',
                      on: campaign,
                    });
                    const showDuplicate =
                      can({
                        perform: 'duplicate',
                        on: campaign,
                      }) || surveysEnabled;

                    if (!showArchive && !showDuplicate) return null;

                    return (
                      <HamburgerMenu testClassName="test-survey-campaign-more-actions">
                        <MenuList>
                          {showDuplicate && (
                            <DuplicateMenuItem
                              campaign={campaign}
                              onAfterDuplicate={refetchData}
                            />
                          )}
                          {showArchive && (
                            <ArchiveMenuItem
                              campaign={campaign}
                              onAfterArchive={refetchData}
                            />
                          )}
                        </MenuList>
                      </HamburgerMenu>
                    );
                  },
                  isNarrow: true,
                },
              ]}
              rows={campaigns}
              keyFn={campaign => campaign.id}
              // TODO: Add an empty state
              emptyState={<div />}
              className="mb-4"
            />
          )}
        />
      </DatatableWrapper>
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({ page, countPerPage, search, filter, sort }: AfterPaginateProps) =>
      get('survey/campaigns', {
        page,
        countPerPage,
        search,
        filter,
        sort,
      }),
    hydrate: {
      campaignCollection: {
        items: { abilities: {} },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      filter,
      sort,
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, filter, sort }),
  })
)(CampaignList);
