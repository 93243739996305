import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components/emptyStates';

type Props = {
  userFullName: string;
  onAllPeriods: boolean;
};

const NoRecordState = ({ userFullName, onAllPeriods }: Props) => {
  return (
    <EmptyStateWithIcon
      className="mb-5"
      inBox={false}
      iconName="edit_note"
      title={
        onAllPeriods
          ? __(
              'No training requests have been created for %1 yet',
              userFullName
            )
          : __(
              'No training requests have been created for %1 on this period yet',
              userFullName
            )
      }
    />
  );
};

export default NoRecordState;
