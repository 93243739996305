import { isEmpty } from 'lodash';
import * as React from 'react';
import { Fragment, useState } from 'react';

import type {
  AttributesOnlyOrganization,
  BaseTrainingCourse,
  User,
} from 'models';

import { handleFormErrors } from 'helpers/api';
import can from 'helpers/can';
import {
  useActiveUser,
  useCurrentOrganization,
  useOrganizationSettings,
} from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  Button,
  Field,
  FieldError,
  Flex,
  Icon,
  Input,
  Label,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Radio,
  Textarea,
} from 'components';

import PriorityPicker from 'scenes/components/PriorityPicker';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';

import TrainingCoursePicker from '../TrainingCoursePicker';
import CollaboratorsChoiceBlock from './CollaboratorsChoiceBlock';
import { CreateTrainingRequestFormObject } from './types';

type Props = {
  currentTrainingPeriodSlug: string | null;
  concernedUser?: User | null;
  trainingCourse?: BaseTrainingCourse | null;
  onSubmit: (trainingRequest: CreateTrainingRequestFormObject) => Promise<void>;
  onClose: () => void;
};

const getDefaultConcernedUser = (
  organization: AttributesOnlyOrganization,
  user: User
) => {
  return can({
    perform: 'create_training_requests_for_others',
    on: organization,
  })
    ? null
    : user;
};

export default function CreateContent({
  currentTrainingPeriodSlug,
  trainingCourse: trainingCourseFromProp,
  concernedUser: concernedUserFromProp,
  onSubmit,
  onClose,
}: Props) {
  const settings = useOrganizationSettings();

  const trainingCatalogEnabled = settings.trainingCatalogEnabled;

  const organization = useCurrentOrganization();
  const activeUser = useActiveUser();

  const concernedUser =
    concernedUserFromProp || getDefaultConcernedUser(organization, activeUser);

  const [errors, setErrors] = useState<
    Partial<CreateTrainingRequestFormObject>
  >({});
  const [trainingRequest, setTrainingRequest] =
    useState<CreateTrainingRequestFormObject>({
      title: trainingCourseFromProp?.name || '',
      description: '',
      trainee: concernedUser,
      priority: 'medium',
      periodSlug: currentTrainingPeriodSlug || '',
      traineeIds: new Set<string>(),
      collaboratorsChoice: 'single',
      trainingCourseId: trainingCourseFromProp?.id || '',
    });
  const [sendRequestLabel, setSendRequestLabel] = useState(__('Send request'));

  const isTrainingRequestValid = () => {
    return (
      !isEmpty(trainingRequest.title) &&
      !isEmpty(trainingRequest.description) &&
      ((trainingRequest.collaboratorsChoice === 'single' &&
        !isEmpty(trainingRequest.trainee)) ||
        (trainingRequest.collaboratorsChoice === 'multiple' &&
          trainingRequest.traineeIds.size !== 0)) &&
      !isEmpty(trainingRequest.priority) &&
      !isEmpty(trainingRequest.periodSlug)
    );
  };

  const handleSubmit = () =>
    handleFormErrors(() => onSubmit(trainingRequest), setErrors);

  const [selectedOption, setSelectedOption] = useState<
    'catalog' | 'offCatalog'
  >(trainingCourseFromProp === null ? 'offCatalog' : 'catalog');

  const offCatalogInput = () => {
    return (
      <Field>
        <Input
          value={trainingRequest.title || ''}
          placeholder={__('ex: Learn the basics of Microsoft Excel')}
          onChange={title => setTrainingRequest({ ...trainingRequest, title })}
        />
        <FieldError>{errors.title}</FieldError>
      </Field>
    );
  };

  const [trainingCourse, setTrainingCourse] =
    useState<BaseTrainingCourse | null>(trainingCourseFromProp || null);

  const handleChange = (trainingCourse?: BaseTrainingCourse) => {
    setTrainingCourse(trainingCourse || null);
    setTrainingRequest({
      ...trainingRequest,
      title: trainingCourse?.name || '',
      trainingCourseId: trainingCourse?.id || '',
    });
  };

  const fromCatalogSearch = () => {
    return (
      <TrainingCoursePicker
        onChange={handleChange}
        trainingCourse={trainingCourse}
      />
    );
  };

  return (
    <Fragment>
      <ModalCardHead onClose={onClose}>
        <ModalCardTitle>{__('Create training request')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody style={{ paddingBottom: 16 }}>
        {!trainingCatalogEnabled ? (
          <Field>
            <Label>{__('Request summary')}</Label>
            <Input
              // @ts-ignore TSFIXME: Fix strictNullChecks error
              value={trainingRequest.title}
              placeholder={__('ex: Learn the basics of Microsoft Excel')}
              onChange={title =>
                setTrainingRequest({ ...trainingRequest, title })
              }
            />
            <FieldError>{errors.title}</FieldError>
          </Field>
        ) : (
          <div className="mb-2">
            <Field>
              <Flex
                style={{
                  margin: '0 0 16px -10px',
                  flexWrap: 'wrap',
                  gap: '16px 40px',
                }}
              >
                <Radio
                  isChecked={selectedOption === 'catalog'}
                  onChange={() => setSelectedOption('catalog')}
                  label={__('From the catalog')}
                  style={{ flex: 'inherit' }}
                />
                <Radio
                  isChecked={selectedOption === 'offCatalog'}
                  onChange={() => {
                    setSelectedOption('offCatalog');
                    setTrainingRequest({ ...trainingRequest, title: '' });
                  }}
                  label={__('Off-catalog')}
                  style={{ flex: 'auto' }}
                />
              </Flex>
            </Field>
            {selectedOption === 'offCatalog' && offCatalogInput()}
            {selectedOption === 'catalog' && fromCatalogSearch()}
          </div>
        )}

        <Field>
          <Label>{__('Additional information about the request')}</Label>
          <Textarea
            value={trainingRequest.description}
            placeholder={__(
              '- What is the purpose of this request?\n' +
                '- Why is it important for your employee’s development?'
            )}
            rows={5}
            maxRows={10}
            onChange={description =>
              setTrainingRequest({ ...trainingRequest, description })
            }
          />
          <FieldError>{errors.description}</FieldError>
        </Field>
        <Field>
          <CollaboratorsChoiceBlock
            concernedUser={concernedUser}
            trainingRequest={trainingRequest}
            setTrainingRequest={setTrainingRequest}
            errors={errors}
            setSendRequestLabel={setSendRequestLabel}
            collaboratorsChoice={trainingRequest.collaboratorsChoice}
            setCollaboratorsChoice={collaboratorsChoice =>
              setTrainingRequest({
                ...trainingRequest,
                collaboratorsChoice,
              })
            }
          />
        </Field>
        <Flex direction="row" style={{ flexWrap: 'wrap', gap: '0 32px' }}>
          <Field style={{ flex: '1 1 200px', minWidth: 200 }}>
            <Label>{__('Request priority (creation modal)')}</Label>
            <PriorityPicker
              value={trainingRequest.priority}
              onChange={priority =>
                setTrainingRequest({ ...trainingRequest, priority })
              }
            />
            <FieldError>{errors.priority}</FieldError>
          </Field>
          <Field style={{ flex: '1 1 200px', minWidth: 200 }}>
            <Label>{__('Period')}</Label>
            <TrainingPeriodPicker
              renderingStyle="select"
              currentPeriodSlug={trainingRequest.periodSlug}
              onChange={period =>
                setTrainingRequest({
                  ...trainingRequest,
                  periodSlug: period.slug,
                })
              }
            />
          </Field>
        </Flex>
      </ModalCardBody>
      <ModalCardFooter>
        <Button onClick={onClose} color="secondary">
          {__('Cancel')}
        </Button>
        <Button
          disabled={!isTrainingRequestValid()}
          onClick={handleSubmit}
          color="primary"
        >
          <Icon style={{ marginRight: 8 }} name="send" />
          {sendRequestLabel}
        </Button>
      </ModalCardFooter>
    </Fragment>
  );
}
