import classNames from 'classnames';
import React, { ReactNode } from 'react';

import tailwindTheme from 'config/tailwindTheme';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import { FlatPieChart, Text, TooltipOnEllipsis } from 'components';
import { Data } from 'components/FlatPieChart/types';

type BudgetTitleProps = {
  children?: ReactNode;
  isInline?: boolean;
};

const BudgetTitle = ({ children, isInline }: BudgetTitleProps) => {
  if (!children) return null;

  const inlineClassName = isInline
    ? 'w-[200px] overflow-hidden text-ellipsis shrink-[2] whitespace-nowrap'
    : '';

  return (
    <div className={inlineClassName}>
      <TooltipOnEllipsis>{children}</TooltipOnEllipsis>
    </div>
  );
};

type Props = {
  title?: ReactNode;
  isInline?: boolean;
  justifyLegend?: boolean;
  provisionedAmountCents?: number;
  validatedAmountCents: number;
  forecastAmountCents?: number;
  currency: string;
  className?: string;
  testClassName?: string;
};

const BudgetVisualizer = ({
  title,
  isInline,
  justifyLegend = true,
  validatedAmountCents,
  forecastAmountCents,
  provisionedAmountCents,
  currency,
  className,
  testClassName,
}: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const budgetEnvelopesEnabled = featureFlags.includes('budgetEnvelopes');

  const remaining =
    (provisionedAmountCents || 0) -
    validatedAmountCents -
    (forecastAmountCents || 0);

  const data: Array<Data> = [
    {
      value: validatedAmountCents,
      color: tailwindTheme.colors.green[500],
      label: __('MasculineSingular|Validated'),
      testClassName: 'test-validated-budget',
    },
  ];

  if (forecastAmountCents !== undefined) {
    data.push({
      value: forecastAmountCents || null,
      color: tailwindTheme.colors.green[300],
      label: __('MasculineSingular|Forecast'),
      testClassName: 'test-forecast-budget',
    });
  }

  if (budgetEnvelopesEnabled) {
    data.push({
      name: 'remaining',
      value: remaining,
      size: Math.abs(remaining),
      color:
        remaining >= 0
          ? tailwindTheme.colors.basic[200]
          : tailwindTheme.colors.red[400],
      label: __('MasculineSingular|Remaining'),
      testClassName:
        remaining >= 0 ? 'test-remaining-budget' : 'test-off-budget',
    });
  } else {
    data.push({
      name: 'remaining',
      value: Math.abs(remaining),
      color:
        remaining >= 0
          ? tailwindTheme.colors.basic[200]
          : tailwindTheme.colors.red[400],
      label:
        remaining >= 0 ? __('MasculineSingular|Remaining') : __('Off-budget'),
      testClassName:
        remaining >= 0 ? 'test-remaining-budget' : 'test-off-budget',
    });
  }

  const formatValue = (value: number | null) =>
    value ? (
      <Text
        preset="14bs6"
        className={value < 0 ? 'text-red-500' : ''}
        testClassName="test-amount"
      >
        {formatMoney(value, currency)}
      </Text>
    ) : (
      <Text preset="14s6" testClassName="test-amount">
        -
      </Text>
    );

  const formatLegend = ({ name, value }: Data) => {
    if (name === 'remaining' && !value) {
      return (
        <Text
          preset="14s6"
          transformation="italic"
          className="block whitespace-nowrap overflow-hidden text-ellipsis text-text-soften"
        >
          <TooltipOnEllipsis>{__('No defined budget')}</TooltipOnEllipsis>
        </Text>
      );
    }
  };

  return (
    <div
      className={classNames(
        isInline && 'flex items-center',
        className,
        testClassName
      )}
    >
      <BudgetTitle isInline={isInline}>{title}</BudgetTitle>
      <FlatPieChart
        className="flex-grow"
        isCompact
        isInline={isInline}
        isBarVisible={!!remaining}
        justifyLegend={justifyLegend}
        valuePositionInLegend="bottom"
        formatValue={formatValue}
        formatLegend={formatLegend}
        data={data}
      />
    </div>
  );
};

export default BudgetVisualizer;
