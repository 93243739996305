import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { DeprecatedPaginationProps } from 'lib/dataLoader/pagination/types';

import { PaginatedCollection, User, UserHistoryLog } from 'models';

import compositeKey from 'helpers/compositeKey';

import { newDataLoader } from 'lib/dataLoader';
import withDeprecatedStatePagination from 'lib/dataLoader/pagination/DeprecatedStatePaginationFactory';
import { get } from 'redux/actions/api';

import { InfiniteFetchContainer } from 'components';

import YearSections from '../YearSections';
import UserHistoryLogItem from './UserHistoryLogItem';

type Props = {
  user: User;
  currentReviewId: string;
};

type AfterPaginateProps = Props & DeprecatedPaginationProps;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    userHistoryLogsCollection: PaginatedCollection<UserHistoryLog>;
  };

const UserHistory = ({
  currentReviewId,
  userHistoryLogsCollection,
  hasError,
  isFetching,
  getNextPage,
}: AfterDataLoaderProps) => {
  return (
    <InfiniteFetchContainer
      collection={userHistoryLogsCollection}
      getNextPage={getNextPage}
      isFetching={isFetching}
      hasError={hasError}
      render={items => {
        if (items.length === 0) return null;
        return (
          <YearSections
            items={items}
            dateFn={log => log.itemCreatedAt}
            renderItem={log => (
              <UserHistoryLogItem
                key={log.id}
                log={log}
                currentReviewId={currentReviewId}
              />
            )}
          />
        );
      }}
    />
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedStatePagination({}),
  newDataLoader({
    fetch: ({ user, page, countPerPage }: AfterPaginateProps) =>
      get(`users/${user.id}/user_history_logs`, {
        page,
        countPerPage,
      }),
    hydrate: {
      userHistoryLogsCollection: {
        items: {
          item: {},
        },
      },
    },
    cacheKey: ({ page, countPerPage }: AfterPaginateProps) =>
      compositeKey({
        type: 'userHistoryLog',
        page,
        countPerPage,
      }),
  })
)(UserHistory);
