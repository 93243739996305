import React, { Fragment, useState } from 'react';

import type { TrainingPeriod } from 'models/TrainingPeriod';
import type { RouteComponentProps } from 'react-router-dom';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate } from 'helpers/navigation';
import { pathToTrainingBudget } from 'helpers/paths';

import { Button, ContentContainer, Text } from 'components';

import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';

import { CurrentTrainingPeriodContext } from '../Plan';
import BudgetManagementModal from '../Plan/Stats/BudgetCard/BudgetManagementModal';
import PageHeader from '../components/PageHeader';
import usePeriodFromStore from '../hooks/usePeriodFromStore';
import ExchangeRates from './ExchangeRates';
import FundingSources from './FundingSources';
import OverallBudget from './OverallBudget';

type RouterProps = RouteComponentProps<{ periodSlug: string }>;

type Props = RouterProps;

const Budget = ({ match }: Props) => {
  const { periodSlug } = match.params;
  invariant(periodSlug, 'Training period must be defined.');

  // Available from the TrainingPeriodPicker
  const period = usePeriodFromStore(periodSlug);

  const [budgetManagementModalActive, setBudgetManagementModalActive] =
    useState(false);

  return (
    <Fragment>
      <PageHeader periodSlug={periodSlug} />
      <ContentContainer>
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <TrainingPeriodPicker
              renderingStyle="popover"
              currentPeriodSlug={periodSlug}
              onChange={(period: TrainingPeriod) => {
                navigate(pathToTrainingBudget(period.slug));
              }}
            />
            <Button
              onClick={() => setBudgetManagementModalActive(true)}
              color="secondary"
            >
              {__('Edit budget')}
            </Button>
          </div>
          <Text preset="18bs5">{__('Overall budget')}</Text>
          <OverallBudget periodSlug={periodSlug} />
          <Text preset="18bs5">{__('Funding sources')}</Text>
          <FundingSources periodSlug={periodSlug} />
          {period && (
            <Fragment>
              <Text preset="18bs5">{__('Exchange Rates')}</Text>
              <ExchangeRates period={period} />
            </Fragment>
          )}
        </div>
      </ContentContainer>
      {budgetManagementModalActive && (
        <CurrentTrainingPeriodContext.Provider value={period}>
          <BudgetManagementModal
            periodSlug={periodSlug}
            onClose={() => {
              setBudgetManagementModalActive(false);
              // Note: This modal will be deprecated,
              // but we'll need to make sure data is refreshed in [EDIT_UNIQUE]
              // setShouldRefetchStats(true);
            }}
          />
        </CurrentTrainingPeriodContext.Provider>
      )}
    </Fragment>
  );
};

export default Budget;
