import React from 'react';

import type { TrainingPeriod } from 'models';

import invariant from 'helpers/invariant';

import { Select } from 'components';

type Props = {
  currentPeriod: TrainingPeriod | undefined | null;
  periods: Array<TrainingPeriod> | undefined | null;
  onChange: undefined | ((period: TrainingPeriod) => void);
  isFetching: boolean;
  disabled?: boolean;
};

const SelectPicker = ({
  currentPeriod,
  periods,
  onChange,
  isFetching,
  disabled,
}: Props) => {
  const options = !!periods
    ? periods.map(period => ({
        label: period.name,
        value: period.id,
      }))
    : [];

  return (
    <Select
      value={
        !!currentPeriod
          ? {
              label: currentPeriod.name,
              value: currentPeriod.id,
            }
          : null
      }
      options={options}
      onChange={option => {
        invariant(
          option && !Array.isArray(option),
          'Option must exist and cannot be an array'
        );

        const period = !!periods
          ? periods.find(period => period.id === option.value)
          : undefined;
        invariant(period, 'Option ' + option.value + ' was not found');

        onChange && onChange(period);
      }}
      isMulti={false}
      isClearable={false}
      isDisabled={disabled}
      inModal
      isLoading={isFetching}
    />
  );
};

export default SelectPicker;
