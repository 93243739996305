import React, { ComponentType, Fragment } from 'react';
import { compose } from 'redux';

import type { WithDeprecatedPaginationProps } from 'lib/dataLoader/pagination/withDeprecatedPagination';

import { UserCollection, UserFilterSegment } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { pathToUser } from 'helpers/paths';

import {
  DataLoaderProvidedProps,
  DeprecatedPaginationProps,
  newDataLoader,
  withDeprecatedPagination,
} from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  BoxList,
  BoxListItem,
  Button,
  DatatableWrapper,
  FetchContainer,
  Flex,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  SearchBar,
  Text,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';
import ReviewEmptyState from 'scenes/components/userReview/ListEmptyState/index';

type Props = WithDeprecatedPaginationProps & {
  reviewCycleId: string;
  rating: number;
  selectedRating: string;
  selectedSkillAreaTitle: string;
  selectedSkillAreaId: string;
  selectedMatrixId: string;
  selectedLevelId: string;
  userFilter: UserFilterSegment | null;
  displayMockedData: boolean;
  onClose: () => void;
};

type AfterPaginateProps = Props & DeprecatedPaginationProps;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    userCollection: UserCollection;
  };

const AttachedCollabsModal = ({
  rating,
  selectedRating,
  selectedSkillAreaTitle,
  userCollection,
  onClose,
  ...otherProps
}: AfterDataLoaderProps) => {
  const { isFetching, hasError, search, onSearchChange } = otherProps;

  return (
    <ModalCard isActive isLarge onClose={onClose}>
      <ModalCardHead>
        <ModalCardTitle>{__('Employees details')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <Flex direction="column" className="mb-4">
          <Text preset="16bs5.5">{selectedSkillAreaTitle}</Text>
          <Text>
            {__(
              '%1% of reviewees have been rated as %2',
              rating,
              <b>{selectedRating}</b>
            )}
          </Text>
        </Flex>
        <div className="my-4">
          <DatatableWrapper
            collectionInfo={userCollection}
            totalCountRenderer={() => ''}
            renderNoResult={() => (
              <ReviewEmptyState
                title={__('No employee matches your search.')}
              />
            )}
            renderHeader={() => (
              <SearchBar
                value={search}
                placeholder={__('Search by name or email')}
                onChange={onSearchChange}
              />
            )}
            {...otherProps}
          >
            <Box isInset className="mt-4 mb-2">
              <BoxList>
                <FetchContainer
                  isFetching={isFetching}
                  hasError={hasError}
                  loadingStyle="overlay"
                  render={() => (
                    <Fragment>
                      {!!userCollection &&
                        userCollection.users.map(user => (
                          <BoxListItem key={user.id}>
                            <UserAvatar
                              user={user}
                              link={pathToUser(user.id)}
                              withJobTitle
                            />
                          </BoxListItem>
                        ))}
                    </Fragment>
                  )}
                />
              </BoxList>
            </Box>
          </DatatableWrapper>
        </div>
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default compose<ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({
      reviewCycleId,
      selectedRating,
      selectedSkillAreaId,
      selectedMatrixId,
      selectedLevelId,
      userFilter,
      page,
      search,
      displayMockedData,
    }: AfterPaginateProps) => {
      if (displayMockedData) {
        return get('users', { page, countPerPage: 5, search });
      } else {
        return get('skills/stats/development_needs/associated_users', {
          page,
          countPerPage: 5,
          search,
          areaIds: selectedSkillAreaId,
          ratings: selectedRating,
          matrixIds: selectedMatrixId,
          levelIds: selectedLevelId,
          reviewCycleIds: reviewCycleId,
          userFilter,
        });
      }
    },
    hydrate: { userCollection: { users: {} } },
    cacheKey: ({ page, countPerPage, search }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search }),
  })
)(AttachedCollabsModal);
