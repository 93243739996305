import React from 'react';

import classNames from 'helpers/classNames';
import { formatMoney } from 'helpers/money';

import { Text } from 'components';

import PercentChangeIndicator from './PercentChangeIndicator';

type Props = {
  label: string;
  amount: number;
  currency: string;
  blurred: boolean;
  percentChange: number | null | undefined;
  inUserPanel?: boolean;
};

const SalaryInfo = ({
  label,
  amount,
  currency,
  blurred,
  percentChange,
  inUserPanel,
}: Props) => (
  <div className="mt-1 flex items-center flex-wrap min-h-6">
    <Text
      preset="14s6"
      color="soften"
      className={classNames({ 'w-24': inUserPanel, 'w-[400px]': !inUserPanel })}
    >
      {label}
    </Text>
    <Text preset="14s6" className={classNames('ml-2', { 'blur-sm': blurred })}>
      {blurred ? 1000000 : formatMoney(amount, currency)}
    </Text>
    {!blurred && !!percentChange && (
      <PercentChangeIndicator percentChange={percentChange} />
    )}
  </div>
);

export default SalaryInfo;
