import React from 'react';
import { compose } from 'redux';

import { PaginatedCollection, TrainingSession, UserReview } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  DataLoaderProvidedProps,
  DeprecatedPaginationProps,
  newDataLoader,
} from 'lib/dataLoader';
import withDeprecatedStatePagination from 'lib/dataLoader/pagination/DeprecatedStatePaginationFactory';
import { get } from 'redux/actions/api';

import { EmptyStateWithIcon, InfiniteFetchContainer } from 'components';

import YearSections from '../YearSections';
import TrainingSessionItem from './TrainingSessionItem';

type Props = {
  userReview: UserReview;
};
type AfterPaginateProps = Props & DeprecatedPaginationProps;
type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    trainingSessionsCollection: PaginatedCollection<TrainingSession>;
  };

const TrainingSessions = ({
  trainingSessionsCollection,
  hasError,
  isFetching,
  getNextPage,
}: AfterDataLoaderProps) => {
  return (
    <InfiniteFetchContainer
      collection={trainingSessionsCollection}
      getNextPage={getNextPage}
      isFetching={isFetching}
      hasError={hasError}
      emptyState={
        <EmptyStateWithIcon
          inBox={false}
          iconName="school"
          title={__('No training session')}
          className="mt-4"
        />
      }
      render={items => (
        <YearSections
          items={items}
          dateFn={item => item.startDate || item.endDate}
          noDateLabel={__('Sessions without dates')}
          renderItem={item => <TrainingSessionItem key={item.id} item={item} />}
        />
      )}
    />
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedStatePagination({}),
  newDataLoader({
    fetch: ({ userReview, page, countPerPage }: AfterPaginateProps) =>
      get(`user_reviews/${userReview.id}/training_sessions`, {
        page,
        countPerPage,
      }),
    hydrate: {
      trainingSessionsCollection: {
        items: {
          abilities: {},
        },
      },
    },
    cacheKey: ({ page, countPerPage }: AfterPaginateProps) =>
      compositeKey({
        type: 'trainingSession',
        page,
        countPerPage,
      }),
  })
)(TrainingSessions);
