import React from 'react';

import { ScaleRange, ScaleSpecificStats, SurveyScaleQuestion } from 'models';

import tailwindTheme from 'config/tailwindTheme';

import { __ } from 'helpers/i18n';

import { Text } from 'components';
import { Data as ChartData } from 'components/FlatPieChart/types';

const rangesFromChoicesCount = (choicesCount: number): ScaleRange[] => {
  switch (choicesCount) {
    case 3:
      return [[0], [1], [2]];
    case 4:
      return [[0], [1], [2], [3]];
    case 5:
      return [[0], [1], [2], [3], [4]];
    case 6:
      return [[0], [1], [2, 3], [4], [5]];
    case 7:
      return [[0], [1, 2], [3], [4, 5], [6]];
    case 8:
      return [[0], [1, 2], [3, 4], [5, 6], [7]];
    case 9:
      return [[0, 1], [2, 3], [4], [5, 6], [7, 8]];
    case 10:
      return [
        [0, 1],
        [2, 3],
        [4, 5],
        [6, 7],
        [8, 9],
      ];
    case 11:
      return [
        [0, 1],
        [2, 3],
        [4, 5, 6],
        [7, 8],
        [9, 10],
      ];
    default:
      throw new Error('Invalid stats data');
  }
};

const computeRanges = (question: SurveyScaleQuestion, choicesCount: number) => {
  const ranges = rangesFromChoicesCount(choicesCount);
  if (question.minValue === 0) return ranges;

  return ranges.map(range => range.map(score => score + question.minValue));
};

const colorsFromRangesLength = (len: number): string[] => {
  switch (len) {
    case 3:
      return ['red-fuckup', 'orange-warning', 'green-success'];
    case 4:
      return ['red-fuckup', 'orange', 'light-green', 'green-success'];
    case 5:
      return [
        'red-fuckup',
        'orange',
        'orange-warning',
        'light-green',
        'green-success',
      ];
    default:
      throw new Error('Invalid stats data');
  }
};

const formatLabel = (range: ScaleRange): string =>
  range.length === 1
    ? range[0].toString()
    : `${range[0]} - ${range[range.length - 1]}`;

export const statsToChartData = (
  question: SurveyScaleQuestion,
  stats: ScaleSpecificStats
): ChartData[] => {
  const choicesCount = question.maxValue - question.minValue + 1;
  const ranges = computeRanges(question, choicesCount);
  const colors = colorsFromRangesLength(ranges.length);

  return ranges.map((range, rangeIndex) => {
    const rangeStats = range.map(
      score => stats.find(stat => stat.score === score) || { score, percent: 0 }
    );

    const initialValue: ChartData = {
      label: formatLabel(range),
      value: null,
      tooltipContent: null,
      color: tailwindTheme.colors[colors[rangeIndex]],
    };

    return rangeStats.reduce((prevData, scoreStat) => {
      const tooltipContent = (
        <Text>
          <div>{prevData.tooltipContent}</div>
          <div className={prevData.tooltipContent ? 'mt-2' : ''}>
            {__('Rating: ')}
            {scoreStat.score}/{question.maxValue}
          </div>
          <div>
            {__('Share: ')}
            {scoreStat.percent}%
          </div>
        </Text>
      );

      const value = (prevData.value || 0) + (scoreStat?.percent || 0);

      return {
        ...prevData,
        tooltipContent,
        value,
      };
    }, initialValue);
  });
};
