import React, { Fragment, ReactNode } from 'react';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  OldPathToTrainingSessionIndex,
  pathToForecastTrainingPlan,
  pathToTrainingBudget,
  pathToTrainingPeriodRequests,
  pathToValidatedTrainingPlan,
} from 'helpers/paths';

import { PageHeader as DefaultPageHeader, PageTitle } from 'components';

type Props = {
  periodSlug: string;
  actions?: ReactNode;
};

const PageHeader = ({ periodSlug, actions }: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const plansEnabled = featureFlags.includes('trainingPlans');
  const budgetEnvelopesEnabled = featureFlags.includes('budgetEnvelopes');

  const tabItems = [
    {
      label: __('Training requests'),
      to: pathToTrainingPeriodRequests(periodSlug),
    },
  ];

  if (plansEnabled) {
    tabItems.push({
      label: __('Forecast plan'),
      to: pathToForecastTrainingPlan(periodSlug),
    });
    tabItems.push({
      label: __('Validated plan'),
      to: pathToValidatedTrainingPlan(periodSlug),
    });
    if (budgetEnvelopesEnabled) {
      tabItems.push({
        label: __('Budget'),
        to: pathToTrainingBudget(periodSlug),
      });
    }
  } else {
    tabItems.push({
      label: __('Training sessions'),
      to: OldPathToTrainingSessionIndex(periodSlug),
    });
  }

  return (
    <Fragment>
      <PageTitle title={[__('Training plan')]} />
      <DefaultPageHeader
        title={__('Trainings')}
        actions={actions}
        tabItems={tabItems}
      />
    </Fragment>
  );
};

export default PageHeader;
