import { useEffect } from 'react';

import type { OrganizationTheme } from 'models';

import colors from 'styles/colors';

import { useOrganizationTheme } from 'helpers/hooks';

const CustomStyleManager = () => {
  const organizationTheme = useOrganizationTheme();

  useEffect(() => {
    if (organizationTheme) {
      setCustomStyle(organizationTheme);
    }

    return () => {
      colors.setDefaultColors();
    };
  }, [organizationTheme]);

  const setCustomStyle = (organizationTheme: OrganizationTheme) => {
    if (organizationTheme) {
      colors.setPrimaryColor(organizationTheme.primaryColor);

      if (organizationTheme.navColors) {
        colors.setNavColors(organizationTheme.navColors);
      }
    }
  };

  return null;
};

export default CustomStyleManager;
