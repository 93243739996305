import { omit } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import { UserCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n/index';

import newDataLoader from 'lib/dataLoader/newDataLoader';
import { FilterParam, PaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import { Box, BoxList, DatatableWrapper, EmptyState } from 'components';

import UsersList from './UsersList';

type FetchParams = {
  permission?: string;
};

type Props = {
  fetchPath: string;
  defaultFilter: FilterParam;
  fetchParams?: FetchParams;
};

type AfterPaginateProps = Props & PaginationProps;

type AfterConnectProps = AfterPaginateProps & {
  userCollection: UserCollection;
  isFetching: boolean;
  hasError: boolean;
};

function UserListWithPagination({
  page,
  countPerPage,
  queryParams,
  setPreviousPageParams,
  setQueryParams,
  setNextPageParams,
  userCollection,
  isFetching,
  hasError,
}: AfterConnectProps) {
  const users = userCollection ? userCollection.users : [];
  const { search, filter, userFilters } = queryParams || {};

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={
          userCollection
            ? (omit(userCollection, 'users') as UserCollection)
            : null
        }
        search={search}
        page={page}
        countPerPage={countPerPage}
        getPreviousPage={setPreviousPageParams}
        getNextPage={setNextPageParams}
        onQueryParamsChange={setQueryParams}
        withSearch
        filters={[
          { param: 'active', label: __('Active') },
          { param: 'suspended', label: __('Suspended') },
        ]}
        filter={filter}
        userFilters={userFilters}
        searchPlaceholder={__('Search users')}
        isFetching={isFetching}
        hasError={hasError}
        renderNoResult={() => (
          <EmptyState title={__('No user matches your search')} inBoxList />
        )}
        withUserMultiFilters
      >
        <BoxList>
          <UsersList
            users={users}
            isFetching={isFetching}
            hasError={hasError}
            displayActions
          />
        </BoxList>
      </DatatableWrapper>
    </Box>
  );
}

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
      fetchPath,
      fetchParams,
    }: AfterPaginateProps) => {
      return get(fetchPath, {
        page,
        countPerPage,
        search,
        filter,
        userFilters,
        ...fetchParams,
      });
    },
    hydrate: {
      userCollection: {
        users: {
          manager: {},
          abilities: {},
        },
      },
    },
    cacheKey: ({ page, countPerPage, queryParams }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, queryParams }),
  })
)(UserListWithPagination);
