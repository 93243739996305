import React, { Fragment, useState } from 'react';

import { __ } from 'helpers/i18n';
import { pathToNewTrainingCourse } from 'helpers/navigation';

import { getTagLabelsFromQueryParams } from 'lib/dataLoader/pagination/paginationParams';

import { Button, ContentContainer, Icon } from 'components';

import TagPicker from 'scenes/components/TagPicker';

import PageHeader from '../components/PageHeader';
import CourseListWithPagination from './CourseListWithPagination';

const Courses = () => {
  const defaultTagLabels = getTagLabelsFromQueryParams();
  const [selectedTagLabels, setSelectedTagLabels] =
    useState<Array<string>>(defaultTagLabels);

  return (
    <Fragment>
      <PageHeader />

      <ContentContainer>
        <div className="flex justify-between mb-4">
          <TagPicker
            context="training"
            className="min-w-[200px]"
            selectedTagLabels={selectedTagLabels}
            placeholder={__('Tags')}
            onTagsChange={tags =>
              setSelectedTagLabels(tags.map(tag => tag.label))
            }
          />
          <Button color="primary" to={pathToNewTrainingCourse()}>
            <Icon name="add" className={'mr-2'} />
            {__('Add a training course')}
          </Button>
        </div>
        <CourseListWithPagination
          additionalQueryParams={{ tagLabels: selectedTagLabels }}
        />
      </ContentContainer>
    </Fragment>
  );
};
export default Courses;
