import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { AllForecastFundingSourceStats } from 'models';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Box, FetchContainer } from 'components';

import BudgetVisualizer from '../components/BudgetVisualizer';

type Props = {
  periodSlug: string;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    fundingSourcesStats: AllForecastFundingSourceStats;
  };

const FundingSources = ({
  fundingSourcesStats,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <Box className="mb-0">
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => (
          <div className="flex flex-col gap-4">
            {fundingSourcesStats.map(sourceStats => (
              <BudgetVisualizer
                key={sourceStats.id}
                title={sourceStats.title}
                isInline
                testClassName="test-funding-source-budget-visualizer"
                provisionedAmountCents={sourceStats.provisionedAmountCents}
                validatedAmountCents={sourceStats.validatedAmountCents}
                forecastAmountCents={sourceStats.forecastAmountCents}
                currency={sourceStats.currency}
              />
            ))}
          </div>
        )}
      />
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  newDataLoader({
    fetch: ({ periodSlug }: Props) =>
      get(
        `training/periods/${periodSlug}/plans/forecast/funding_sources_stats`
      ),
    hydrate: {
      fundingSourcesStats: {
        budgetItem: {
          abilities: {},
        },
      },
    },
  })
)(FundingSources);
