import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/pagination/types';
import type { PaginatedCollection, TrainingSession } from 'models';

import compositeKey from 'helpers/compositeKey';
import { startToEndDateLabel } from 'helpers/date';
import { __, n__ } from 'helpers/i18n';
import { assertDefined } from 'helpers/invariant';

import { newDataLoader } from 'lib/dataLoader';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  FetchContainer,
  Number,
  SimpleTable,
  Text,
} from 'components';

import AvatarList from 'scenes/components/AvatarList';

import NoRecordState from './NoRecordState';
import NoResultState from './NoResultState';

type Props = {
  currentPeriodSlug: string;
} & WithPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = DataLoaderProvidedProps &
  AfterPaginateProps & {
    sessionCollection: PaginatedCollection<TrainingSession>;
  };

const SessionList = ({
  page,
  countPerPage,
  queryParams,
  setPreviousPageParams,
  setNextPageParams,
  setQueryParams,
  onSortChange,
  sessionCollection,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const { search, filter, userFilters } = queryParams;

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={sessionCollection}
        search={search}
        page={page}
        countPerPage={countPerPage}
        getPreviousPage={setPreviousPageParams}
        getNextPage={setNextPageParams}
        onQueryParamsChange={setQueryParams}
        isFetching={isFetching}
        hasError={hasError}
        totalCountRenderer={(count?: number | null) =>
          n__('%1 training session', '%1 training sessions', count || 0)
        }
        renderNoResult={() => <NoResultState />}
        renderNoRecord={() => <NoRecordState />}
        userFilters={userFilters}
        filter={filter}
        filters={[
          {
            param: 'to_come',
            label: __('To come'),
          },
          { param: 'completed', label: __('Completed') },
          { param: 'all', label: __('All') },
        ]}
        withSearch
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <SimpleTable
              rows={sessionCollection?.items || []}
              keyFn={session => session.id}
              columns={[
                {
                  header: __('Session date'),
                  cell: session => {
                    if (
                      session.startDate === null &&
                      session.endDate === null
                    ) {
                      return (
                        <Text color="light" transformation="italic">
                          {__('No dates')}
                        </Text>
                      );
                    }

                    return startToEndDateLabel(
                      session.startDate,
                      session.endDate,
                      {
                        fromToLabelsAlwaysVisible: true,
                      }
                    );
                  },
                  activeSort:
                    queryParams.sort && queryParams.sort['start_date'],
                  onSort: () => onSortChange('start_date'),
                },
                {
                  header: __('Session name'),
                  cell: session => session.name || __('Untitled session'),
                  activeSort:
                    queryParams.sort && queryParams.sort['session_name'],
                  onSort: () => onSortChange('session_name'),
                },
                {
                  header: __('Participants'),
                  cell: session => {
                    const firstParticipants = assertDefined(
                      session.firstParticipants,
                      'firstParticipants should be defined on session on this page'
                    );
                    return (
                      <AvatarList
                        users={firstParticipants.map(
                          participant => participant.user
                        )}
                        totalUserCount={session.participantCount}
                        maxInlineUsers={4}
                        renderNoUser={() => (
                          <Text color="light" transformation="italic">
                            {__('No participants')}
                          </Text>
                        )}
                      />
                    );
                  },
                },
                {
                  header: __('Number of hours'),
                  cell: session =>
                    session.durationInHours
                      ? __('%1h', <Number value={session.durationInHours} />)
                      : '-',
                },
              ]}
              className="mb-2"
              rowClassName="test-training-session-row"
            />
          )}
        />
      </DatatableWrapper>
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters, sort },
      currentPeriodSlug,
    }: AfterPaginateProps) =>
      get(`training/sessions`, {
        page,
        countPerPage,
        search,
        filter,
        userFilters,
        sort,
        periodSlug: currentPeriodSlug,
        validated: true,
      }),
    hydrate: {
      sessionCollection: {
        items: {
          firstParticipants: {
            user: {},
          },
          abilities: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters, sort },
      currentPeriodSlug,
    }: AfterPaginateProps) =>
      compositeKey({
        isValidatedPlan: true,
        page,
        sort,
        countPerPage,
        search,
        filter,
        userFilters,
        periodSlug: currentPeriodSlug,
      }),
  })
)(SessionList);
