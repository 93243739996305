import React, {
  ComponentProps,
  Fragment,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { PaginatedCollection } from 'models';

import { PaginationChangeMethods } from 'lib/dataLoader/pagination/types';

import FetchContainer from './FetchContainer';
import Loading, { DEFAULT_SIZE } from './Loading';

type Props<T> = Pick<
  ComponentProps<typeof FetchContainer>,
  'isFetching' | 'hasError' | 'errorMessage'
> & {
  collection: PaginatedCollection<T> | undefined;
  getNextPage: PaginationChangeMethods['getNextPage'];
  emptyState?: ReactNode;
  scrollArea?: RefObject<HTMLElement>;
  render: (items: T[]) => Exclude<ReactNode, undefined>;
};

/**
 * A component that implements infinite scrolling behavior, an alternative
 * way to render a list of items instead of using pagination.
 *
 * ## How to turn FetchContainer into InfiniteFetchContainer
 *
 * - In the backend, make sure the collection ID contains the page number
 * - Replace FetchContainer with InfiniteFetchContainer
 * - Forward the `collection` and `getNextPage` props from the pagination HoC
 * - The `render` function receives all the loaded items
 * - Remove `PaginationProps` from the component Props type
 * - Replace `withPagination` by `withDeprecatedStatePagination({})`
 * - Declare dependency props that should refresh the list (if any)
 *   - Don't use refetchData
 *   - Specify them in the newDataLoader cacheKey
 *   - Specify them in the `resetPageFor` option
 *    ```js
 *      withDeprecatedStatePagination({
 *        resetPageFor: ({ someFilter, someSearch }) => [
 *          someFilter,
 *          someSearch,
 *        ],
 *      })
 *    ```
 */
const InfiniteFetchContainer = <T,>({
  collection,
  getNextPage,
  emptyState,
  render,
  scrollArea,
  isFetching,
  ...fetchContainerProps
}: Props<T>) => {
  // Items list
  const [pages, setPages] = useState<T[][]>([]);
  const items = pages.flat();

  // Append new pages
  useEffect(() => {
    if (!collection || isFetching) return;
    setPages(pages => {
      return [...pages.slice(0, collection.page - 1), collection.items];
    });
  }, [collection, isFetching]);

  // Observer: Next page when intersection
  const observer = useMemo(
    () =>
      new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              if (getNextPage) getNextPage();
            }
          });
        },
        {
          root: scrollArea?.current,
          threshold: 0.5,
        }
      ),
    [scrollArea, getNextPage]
  );

  // Observed definition
  const [observedElement, setObservedElement] = useState<HTMLDivElement | null>(
    null
  );
  const observeElement = useCallback((node: HTMLDivElement) => {
    setObservedElement(node);
  }, []);

  // Observer & Observed: Add event listener
  useEffect(() => {
    if (observedElement) {
      observer.observe(observedElement);
      return () => {
        observer.unobserve(observedElement);
      };
    }
  }, [observer, observedElement]);

  return (
    <Fragment>
      <FetchContainer
        {...fetchContainerProps}
        loadingStyle="none"
        isFetching={isFetching}
        render={() => {
          if (emptyState && items.length === 0) {
            return isFetching ? null : emptyState;
          }

          return render(items);
        }}
      />
      {!isFetching && <div ref={collection?.hasNext ? observeElement : null} />}
      <Loading
        containerStyle={{
          height: DEFAULT_SIZE,
          display: !collection || collection?.hasNext ? 'flex' : 'none',
          paddingTop: '20px',
          paddingBottom: '40px',
        }}
        style={{
          display: isFetching ? '' : 'none',
        }}
      />
    </Fragment>
  );
};

export default InfiniteFetchContainer;
