import classNames from 'classnames';
import React, { ReactNode } from 'react';

import Bar from './Bar';
import LegendItem from './LegendItem';
import { Data } from './types';

type Props = {
  data: Data[];
  isCompact?: boolean;
  isInline?: boolean;
  isBarVisible?: boolean;
  justifyLegend?: boolean;
  valuePositionInLegend?: 'top' | 'bottom' | 'hidden';
  className?: string;
  /**
   * Ignored if it returns undefined
   */
  formatValue?: (value: number | null) => ReactNode;
  /**
   * Ignored if it returns undefined
   */
  formatLegend?: (data: Data) => ReactNode;
};

const FlatPieChart = ({
  data,
  isCompact,
  isInline,
  isBarVisible = true,
  justifyLegend = true,
  valuePositionInLegend = 'top',
  className,
  formatValue,
  formatLegend,
}: Props) => {
  const legendMarginClassName = isCompact ? 'mt-3' : 'mt-4';

  const legendItems = data.map((data, i) => {
    let legendLayoutClasses = '';
    if (isInline) {
      legendLayoutClasses = 'basis-[190px] min-w-0';
    } else if (justifyLegend) {
      legendLayoutClasses = 'flex-1';
    }
    return (
      <LegendItem
        key={i}
        compact={isCompact}
        data={data}
        valuePositionInLegend={isInline ? 'right' : valuePositionInLegend}
        formatValue={formatValue}
        formatLegend={formatLegend}
        className={classNames(
          legendLayoutClasses,
          data.testClassName && `${data.testClassName}-legend-item`
        )}
      />
    );
  });

  if (isInline) {
    return (
      <div className={classNames('flex gap-4 items-center min-w-0', className)}>
        <Bar
          isCompact={isCompact}
          data={data}
          visible={isBarVisible}
          className="flex-1 w-auto mr-[calc(8%-12px)]"
        />
        {legendItems}
      </div>
    );
  } else {
    return (
      <div className={className}>
        <Bar isCompact={isCompact} data={data} visible={isBarVisible} />
        <div
          className={classNames(
            `flex ${legendMarginClassName}`,
            justifyLegend ? 'gap-4' : 'gap-[min(56px,10%)]'
          )}
        >
          {legendItems}
        </div>
      </div>
    );
  }
};

export default FlatPieChart;
