import React, { useState } from 'react';

import type { User } from 'models';

import { __ } from 'helpers/i18n';

import { Box, Button, Can, FeatureFlagged, Icon } from 'components';

import TrainingPeriodPicker, {
  fakeAllPeriodsTrainingPeriod,
} from 'scenes/components/TrainingPeriodPicker';
import TrainingRequestModal from 'scenes/components/TrainingRequestModal';

import OldRequestTable from './OldRequestTable';
import RequestTable from './RequestTable';

type Props = {
  user: User;
};

const TrainingRequests = ({ user }: Props) => {
  const [requestCreationModalActive, setRequestCreationModalActive] =
    useState(false);
  const [shouldRefetchRequests, setShouldRefetchRequests] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(
    fakeAllPeriodsTrainingPeriod()
  );

  return (
    <FeatureFlagged
      flag="revampTrainingProfileAndReview"
      alternativeChildren={
        <Box>
          <OldRequestTable userId={user.id} />
        </Box>
      }
    >
      <div className="flex justify-between mb-4">
        <div className="w-1/3">
          <TrainingPeriodPicker
            renderingStyle={'select'}
            currentPeriodSlug={selectedPeriod.slug}
            onChange={period => setSelectedPeriod(period)}
            allPeriodsOptionAvailable
          />
        </div>
        <Can perform={'create_training_request'} on={user}>
          <Button
            onClick={() => setRequestCreationModalActive(true)}
            color="primary"
          >
            <Icon name="add" className="mr-1" />
            {__('Make a request')}
          </Button>
        </Can>
      </div>
      <Box>
        <RequestTable
          user={user}
          periodSlug={selectedPeriod.slug}
          shouldRefetchRequests={shouldRefetchRequests}
          setShouldRefetchRequests={setShouldRefetchRequests}
          additionalQueryParams={{ sort: { requestedAt: 'desc' } }}
        />
      </Box>
      {requestCreationModalActive && (
        <TrainingRequestModal
          isActive
          concernedUser={user}
          currentTrainingPeriodSlug={
            selectedPeriod.slug === 'all_periods' ? null : selectedPeriod.slug
          }
          onClose={() => setRequestCreationModalActive(false)}
          onAfterSubmit={() => setShouldRefetchRequests(true)}
        />
      )}
    </FeatureFlagged>
  );
};

export default TrainingRequests;
