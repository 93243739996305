import React, { Fragment } from 'react';

import {
  TrainingSession,
  TrainingSessionValidationActionSummary,
} from 'models/TrainingSession';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, post } from 'redux/actions/api';

import {
  Button,
  FetchContainer,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import useSessionValidationSummaryContent from '../hooks/useSessionValidationSummaryContent';

type Props = {
  session: TrainingSession;
  onClose: () => void;
  onAfterUpdate: () => void;
};

type AfterDataLoaderProps = DataLoaderProvidedProps &
  Props & { summary: TrainingSessionValidationActionSummary };

const ValidateSessionModal = ({
  session,
  isFetching,
  hasError,
  onClose,
  onAfterUpdate,
  summary,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();

  const {
    renderTitleText,
    renderContent,
    renderValidateButtonText,
    checkedOptions,
  } = useSessionValidationSummaryContent(summary);

  const validateSession = async () => {
    await dispatch(
      post(`training/sessions/${session.id}/validate`, checkedOptions)
    );

    onClose();
    onAfterUpdate();
  };

  return (
    <ModalCard isActive isLarge onClose={onClose}>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => (
          <Fragment>
            <ModalCardHead onClose={onClose}>
              <ModalCardTitle>{renderTitleText()}</ModalCardTitle>
            </ModalCardHead>
            <ModalCardBody>{renderContent()}</ModalCardBody>
            <ModalCardFooter>
              <Button color="primary" onClick={validateSession}>
                {renderValidateButtonText()}
              </Button>
              <Button color="secondary" onClick={onClose}>
                {__('Cancel')}
              </Button>
            </ModalCardFooter>
          </Fragment>
        )}
      />
    </ModalCard>
  );
};

export default newDataLoader({
  fetch: ({ session }: Props) =>
    get(`training/sessions/${session.id}/validation_summary`),
  hydrate: { summary: {} },
})(ValidateSessionModal);
