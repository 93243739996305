import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type {
  PaginatedCollection,
  PeopleReviewPotentialSuccessor,
} from 'models';

import compositeKey from 'helpers/compositeKey';
import { useOrganizationPlan } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { concatenatedSkillLevelWithMatrixTitle } from 'helpers/models/skills/skillLevel';

import { newDataLoader } from 'lib/dataLoader';
import { PaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import {
  Checkbox,
  DatatableWrapper,
  FetchContainer,
  Icon,
  SimpleTable,
  StrictlySanitizedHtml,
  Text,
  Tooltip,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';
import PeopleGroupTagList from 'scenes/peopleReview/cycles/Manage/components/PeopleGroupTagList';

type Props = {
  reviewId: string;
  revieweeFullName: string;
  selectedSuccessorIds: string[];
  toggleSelectedSuccessor: (successorId: string) => void;
  countPerPage: number;
  withUrlChange: boolean;
};

type AfterPaginateProps = Props & PaginationProps;

type AfterDataloaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    successorCollection: PaginatedCollection<PeopleReviewPotentialSuccessor>;
  };

const SuccessorChooser = ({
  selectedSuccessorIds,
  revieweeFullName,
  toggleSelectedSuccessor,
  successorCollection,
  isFetching,
  hasError,
  page,
  countPerPage,
  queryParams: { search, userFilters },
  setNextPageParams,
  setPreviousPageParams,
  setQueryParams,
}: AfterDataloaderProps) => {
  const { skillsAndCareersEnabled } = useOrganizationPlan();
  const canSelectNewSuccessors = selectedSuccessorIds.length < 5;

  return (
    <DatatableWrapper
      collectionInfo={successorCollection}
      userFilter={successorCollection?.userFilter}
      isFetching={isFetching}
      hasError={hasError}
      page={page}
      countPerPage={countPerPage}
      search={search}
      userFilters={userFilters}
      onQueryParamsChange={setQueryParams}
      getNextPage={setNextPageParams}
      getPreviousPage={setPreviousPageParams}
      withUserMultiFilters
      withSearch
      showTotalRecordCount
    >
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="overlay"
        render={() => (
          <SimpleTable
            columns={[
              {
                header: __('Employee'),
                cell: successor => {
                  const isSelected = selectedSuccessorIds.includes(
                    successor.id
                  );

                  return (
                    <div className="flex flex-row items-center">
                      <Tooltip
                        enabled={!canSelectNewSuccessors && !isSelected}
                        content={__(
                          'You cannot select more than 5 successors.'
                        )}
                      >
                        <Checkbox
                          onChange={() => toggleSelectedSuccessor(successor.id)}
                          isChecked={isSelected}
                          disabled={!canSelectNewSuccessors && !isSelected}
                          size="tiny"
                          className="mr-2"
                        />
                      </Tooltip>
                      <UserAvatar withJobTitle user={successor} />
                    </div>
                  );
                },
              },
              skillsAndCareersEnabled
                ? {
                    header: __('Skills matrix'),
                    cell: successor => {
                      const level = successor.currentCareerLevel?.skillsLevel;
                      if (!level) return <Text color="grey">-</Text>;

                      return concatenatedSkillLevelWithMatrixTitle(level);
                    },
                  }
                : undefined,
              {
                header: __('Information'),
                cell: successor => {
                  const groups = successor.peopleGroups;
                  if (groups.length === 0) return <Text color="grey">-</Text>;

                  return <PeopleGroupTagList peopleGroups={groups} />;
                },
                isNarrow: true,
              },
            ]}
            rows={successorCollection?.items || []}
            keyFn={successor => successor.id}
            highlightedRowFn={successor => successor.suggested}
            highlightExplanation={
              <span>
                {__('Employees suggested by Elevo')}
                <Tooltip
                  content={
                    <StrictlySanitizedHtml
                      html={__(
                        'Employees being <b>on the same skills matrix</b> as %1.',
                        revieweeFullName
                      )}
                    />
                  }
                >
                  <Icon name="info" className="ml-2" />
                </Tooltip>
              </span>
            }
            className="mb-4"
            useSmallerYPadding
            lineCountPerRow={2}
          />
        )}
      />
    </DatatableWrapper>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, userFilters },
      reviewId,
    }: AfterPaginateProps) =>
      get(`people_user_reviews/${reviewId}/potential_successors`, {
        page,
        countPerPage,
        search,
        userFilters,
      }),
    hydrate: {
      successorCollection: {
        items: {
          currentCareerLevel: { skillsLevel: {} },
          peopleGroups: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, userFilters },
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, userFilters }),
  })
)(SuccessorChooser);
