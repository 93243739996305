import React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { pathToHome } from 'helpers/paths';

import { Redirect, Route, Switch } from 'components';

import SimbelTrainingRoutes from 'scenes/components/simbel/SimbelTrainingRoutes';

import CatalogRoutes from './Catalog/Routes';
import CatalogManagementRoutes from './CatalogManagement/Routes';
import ManagerDashboard from './ManagerDashboard';
import PlanManagementRoutes from './PlanManagement/Routes';

const ManagerDashboardRoutes = () => {
  const { path } = useRouteMatch();
  const organization = useCurrentOrganization();

  const canShowTrainingCatalog = can({
    perform: 'show_training_catalog',
    on: organization,
  });

  const canShowTrainingCatalogManagement = can({
    perform: 'show_training_catalog_management_menu',
    on: organization,
  });

  const canShowManagerTrainingDashboard = can({
    perform: 'show_manager_training_dashboard',
    on: organization,
  });

  const canShowTrainingPlanMenu = can({
    perform: 'show_training_plan_menu',
    on: organization,
  });

  return (
    <Switch>
      {canShowTrainingCatalog && (
        <Route path={`${path}/catalog`} component={CatalogRoutes} />
      )}

      {canShowManagerTrainingDashboard && (
        <Route
          path={`${path}/:periodSlug/manager-dashboard`}
          component={ManagerDashboard}
        />
      )}

      {canShowTrainingPlanMenu && (
        <Route path={`${path}/plan`} component={PlanManagementRoutes} />
      )}

      {canShowTrainingCatalogManagement && (
        <Route path={`${path}`} component={CatalogManagementRoutes} />
      )}

      <Redirect to={pathToHome()} />
    </Switch>
  );
};

const Routes = (props: RouteComponentProps) => {
  const organization = useCurrentOrganization();

  if (organization.isSimbelIntegrationActive) {
    return <SimbelTrainingRoutes {...props} />;
  } else {
    return <ManagerDashboardRoutes />;
  }
};

export default Routes;
