import type { TrainingPeriod } from 'models/TrainingPeriod';

import { useAppSelector } from 'helpers/hooks';

import { hydrateFromStore } from 'lib/dataLoader';

const usePeriodFromStore = (periodSlug: string) => {
  return useAppSelector(
    state =>
      hydrateFromStore(
        state.data,
        {
          resourceType: 'trainingPeriod',
          filter: (item: TrainingPeriod) => item.slug === periodSlug,
        },
        {
          trainingPeriod: {
            exchangeRates: [],
          },
        }
      )[0]
  ) as TrainingPeriod;
};

export default usePeriodFromStore;
