import React from 'react';

import type { TrainingRequest } from 'models';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { BoxList, FetchContainer } from 'components';
import TrainingRequestEmptyState from 'components/TrainingRequestEmptyState';

import TrainingRequestItem from './TrainingRequestItem';

type Props = {
  userId: string;
};

type AfterConnectProps = DataLoaderProvidedProps & {
  trainingRequests: Array<TrainingRequest>;
};

const OldRequestTable = ({
  trainingRequests,
  isFetching,
  hasError,
}: AfterConnectProps) => {
  return (
    <BoxList>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => {
          if (!isFetching && trainingRequests.length === 0) {
            return <TrainingRequestEmptyState />;
          }
          return trainingRequests.map(trainingRequest => (
            <TrainingRequestItem
              key={trainingRequest.id}
              trainingRequest={trainingRequest}
            />
          ));
        }}
      />
    </BoxList>
  );
};

export default newDataLoader({
  fetch: ({ userId }) => get(`/users/${userId}/training_requests`),
  hydrate: {
    trainingRequests: { session: {} },
  },
})(OldRequestTable) as React.ComponentType<Props>;
