import React, { Fragment, useEffect, useState } from 'react';

import { BaseTrainingCourse } from 'models';

import can from 'helpers/can';
import { useAppDispatch, useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { AppDispatch } from 'redux/actions';
import { get } from 'redux/actions/api';

import Icon from 'components/Icon';
import { Button } from 'components/buttons';

import OriginalTrainingRequestModal from 'scenes/components/TrainingRequestModal/TrainingRequestModal';

const fetchCurrentTrainingPeriod = async (dispatch: AppDispatch) => {
  const { response } = await dispatch(get('training/periods/current'));
  if (response?.body) {
    return response.body.data.attributes.slug;
  }
};

type Props = {
  trainingCourse: BaseTrainingCourse | null;
};

const TrainingRequestModalButton = ({ trainingCourse }: Props) => {
  const buttonTitle = trainingCourse
    ? __('Request this training')
    : __('Submit an off-catalog request');

  const organization = useCurrentOrganization();

  const [trainingRequestModalActive, setTrainingRequestModalActive] =
    React.useState(false);
  const [currentTrainingPeriodSlug, setCurrentTrainingPeriodSlug] =
    useState(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchCurrentTrainingPeriod(dispatch).then(trainingPeriodSlug => {
      setCurrentTrainingPeriodSlug(trainingPeriodSlug);
    });
  }, [dispatch]);

  const canCreateTrainingRequest = can({
    perform: 'create_training_request',
    on: organization,
  });

  if (!canCreateTrainingRequest || !currentTrainingPeriodSlug) {
    return null;
  }

  return (
    <Fragment>
      <Button
        color="primary"
        onClick={() => {
          setTrainingRequestModalActive(true);
        }}
      >
        <Icon name="add" className={'mr-2'} />
        {buttonTitle}
      </Button>

      {currentTrainingPeriodSlug && (
        <OriginalTrainingRequestModal
          onClose={() => setTrainingRequestModalActive(false)}
          currentTrainingPeriodSlug={currentTrainingPeriodSlug}
          isActive={trainingRequestModalActive}
          trainingCourse={trainingCourse}
        />
      )}
    </Fragment>
  );
};

export default TrainingRequestModalButton;
