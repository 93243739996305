import React from 'react';

import type { TrainingSession } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import { pathToTrainingSessionDetails } from 'helpers/navigation';

import { Link, Text } from 'components';

type Props = {
  session: TrainingSession | null | undefined;
};

const SessionCell = ({ session }: Props) => {
  if (!session) {
    return (
      <Text transformation="italic" color="light">
        {__('Not linked to a training')}
      </Text>
    );
  }

  if (can({ perform: 'update', on: session })) {
    return (
      <Link
        hasInheritedColor
        to={pathToTrainingSessionDetails(session.id)}
        openInNewTab
      >
        {session.name || __('Untitled session')}
      </Link>
    );
  }

  return <Text>{session.name || __('Untitled session')}</Text>;
};

export default SessionCell;
