import React, { Fragment } from 'react';

import { useOrganizationPlan } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  CheckboxSwitch,
  Column,
  Columns,
  DesignSystem,
  Flex,
  PageTitle,
  Text,
  Title,
} from 'components';

import TrainingPeriodLanding from '../../../trainings/PlanManagement/Landing';

const TrainingSettings = () => {
  const { trainingModuleEnabled } = useOrganizationPlan();

  return (
    <Fragment>
      <PageTitle title={[__('Training'), __('Settings')]} />

      {trainingModuleEnabled ? (
        <div className="bg-white rounded p-5 max-w-[1000px] mx-auto mt-8 md:mt-0">
          <Columns contentVerticallyAligned>
            <Column>
              <Flex verticalAlign className="mb-2">
                <Title
                  size={5}
                  weight="semibold"
                  className="!text-basic-800 mr-5"
                >
                  {__('Training')}
                </Title>
                <Text color="success">{__('Enabled_masculine')}</Text>
              </Flex>
              <Text>
                {__('The product "Training" is enabled on your platform.')}
              </Text>
            </Column>
            <Column isNarrow>
              <CheckboxSwitch disabled value onChange={() => {}} size="small" />
            </Column>
          </Columns>
        </div>
      ) : (
        <DesignSystem version={2}>
          <div className="mt-10 md:mt-0">
            <TrainingPeriodLanding />
          </div>
        </DesignSystem>
      )}
    </Fragment>
  );
};

export default TrainingSettings;
