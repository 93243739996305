import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import can from 'helpers/can';
import {
  useActiveUser,
  useCurrentOrganization,
  useSession,
} from 'helpers/hooks';
import { pathToReviewCycles } from 'helpers/paths';

import { FeatureFlagged, Redirect, Route, Switch } from 'components';

import EntityIndex from './EntityIndex';
import Integrations from './Integrations';
import NotificationsSettings from './NotificationsSettings';
import Rights from './Rights';
import SettingsRoutes from './Settings/SettingsRoutes';
import SyncDetails from './SyncDetails';
import UserList from './UserList';
import CycleRoutes from './reviewCycles/Routes';
import TemplateRoutes from './reviewTemplates/Routes';

function Routes() {
  const match = useRouteMatch();
  const { path } = match;
  const { isImpersonated } = useSession();
  const user = useActiveUser();
  const organization = useCurrentOrganization();

  const showReviewCampaignMenu = can({
    perform: 'show_review_campaign_menu',
    on: organization,
  });
  const showReviewTemplateMenu = can({
    perform: 'show_review_template_menu',
    on: organization,
  });
  const showUserDirectoryMenu = can({
    perform: 'show_user_directory_menu',
    on: organization,
  });

  return (
    <FeatureFlagged
      flag="adminRights"
      render={adminRightsEnabled => (
        <Switch>
          {showReviewCampaignMenu && (
            <Route path={`${path}/cycles`}>
              <CycleRoutes />
            </Route>
          )}
          {showReviewTemplateMenu && (
            <Route path={`${path}/templates`}>
              <TemplateRoutes />
            </Route>
          )}
          {showUserDirectoryMenu && (
            <Route path={`${path}/users`} component={UserList} />
          )}
          {adminRightsEnabled && user.isOrganizationAdmin && (
            <Route path={`${path}/rights`} component={Rights} />
          )}
          {isImpersonated && user.isOrganizationAdmin && (
            <Route path={`${path}/entities`} component={EntityIndex} />
          )}
          {user.isOrganizationAdmin && (
            <Route path={`${path}/settings`} component={SettingsRoutes} />
          )}
          {user.isOrganizationAdmin && (
            <Route path={`${path}/sync/:id`} component={SyncDetails} />
          )}
          {isImpersonated && user.isOrganizationAdmin && (
            <Route path={`${path}/integrations`} component={Integrations} />
          )}
          {user.isOrganizationAdmin && (
            <Route
              path={`${path}/notifications`}
              component={NotificationsSettings}
            />
          )}
          <Redirect to={pathToReviewCycles('one_on_one')} />
        </Switch>
      )}
    />
  );
}

export default Routes;
