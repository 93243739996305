import React from 'react';

import { PercentageInput } from 'components/formElements';

import { EditableFieldProps } from '../EditableField';
import SyncedField from '../SyncedField';

const WorkingTimeRatioField = ({
  value,
  onChange,
  fieldSynced,
  syncedFieldOptions,
}: EditableFieldProps) => {
  return fieldSynced ? (
    <SyncedField lockedFieldValue={value?.toString()} {...syncedFieldOptions} />
  ) : (
    <PercentageInput value={value} onChange={onChange} />
  );
};

export default WorkingTimeRatioField;
