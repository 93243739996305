import type {
  PaginationSearchParams,
  QueryParams,
} from 'lib/dataLoader/pagination/types';

import { castHashValuesToBoolean } from 'helpers/hash';
import queryString from 'helpers/queryString';

import { hasShape, stringType } from 'types/predicates/WithShape';

export const DEFAULT_PAGINATION: PaginationSearchParams = {
  page: 1,
  countPerPage: 10,
  search: '',
  filter: { all: true },
  sort: {},
  userFilter: null,
};

export function parsePaginationParams(
  queryParams: QueryParams
): Partial<PaginationSearchParams> {
  const parsedParams: Partial<PaginationSearchParams> = { ...queryParams };

  if (queryParams.page) {
    parsedParams.page = parseInt(queryParams.page as string, 10);
  }

  if (queryParams.countPerPage) {
    parsedParams.countPerPage = parseInt(
      queryParams.countPerPage as string,
      10
    );
  }

  if (queryParams.search && typeof queryParams.search === 'string') {
    parsedParams.search = queryParams.search;
  }

  if (queryParams.userFilter && typeof queryParams.userFilter === 'object') {
    parsedParams.userFilter = queryParams.userFilter as any;
  }

  if (queryParams.sort && typeof queryParams.sort === 'object') {
    parsedParams.sort = queryParams.sort as any;
  }

  if (queryParams.filter && typeof queryParams.filter === 'object') {
    parsedParams.filter = castHashValuesToBoolean(queryParams.filter);
  }

  return parsedParams;
}

export const getUserFiltersFromQueryParams = (queryParams: QueryParams) => {
  const paginationParams = parsePaginationParams(queryParams);
  const hasUserFilters = hasShape(paginationParams, {
    queryParams: { userFilters: {} },
  });
  const hasEmptyUserFilters = hasShape(paginationParams, {
    queryParams: { userFilters: stringType },
  });

  if (
    hasUserFilters &&
    paginationParams.queryParams.userFilters !== undefined
  ) {
    return paginationParams.queryParams.userFilters;
  }
  if (hasEmptyUserFilters) {
    return {};
  }
};

export const getTagLabelsFromQueryParams = (): Array<string> => {
  const queryParams = queryString.parse(window.location.search) as QueryParams;

  const paginationParams = parsePaginationParams(queryParams);
  const hasTagLabels = hasShape(paginationParams, {
    queryParams: { tagLabels: [] },
  });

  if (hasTagLabels && paginationParams.queryParams.tagLabels !== undefined) {
    return paginationParams.queryParams.tagLabels;
  }
  return [];
};
