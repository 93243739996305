import React, { useState } from 'react';

import type { UserFilterSegment } from 'models';

import { history } from 'config/history';

import { useActiveUser, useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import queryString from 'helpers/queryString';

import { parsePaginationParams } from 'lib/dataLoader/pagination/paginationParams';

import {
  ContentContainer,
  DesignSystem,
  Level,
  LevelRight,
  PageHeader,
  StrictlySanitizedHtml,
  Text,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import UserFilter from 'scenes/components/UserFilter';
import UserMultiFilters from 'scenes/components/UserMultiFilters';

import ExportButton from './ExportButton';
import OldUserObjectivesListWithPagination from './OldUserObjectivesListWithPagination';
import UserObjectivesListWithPagination from './UserObjectivesListWithPagination';
import UserObjectivesStats from './UserObjectivesStats';

const ObjectiveDashboard = () => {
  const activeUser = useActiveUser();
  const paginationParams = parsePaginationParams(
    // @ts-expect-error: TSFIXME
    queryString.parse(window.location.search)
  );
  // TODO: multifilters: remove this 2 lines when FF is enabled for everyone
  const { featureFlags } = useCurrentOrganization();
  const withUserMultiFilters = featureFlags.includes('userMultifilters');

  const areUserFiltersInQueryParams =
    // @ts-expect-error TSFIXME userFilters does't exist in PaginationSearchParams
    paginationParams.queryParams?.userFilters !== undefined;
  const defaultUserFilters = areUserFiltersInQueryParams
    ? // @ts-expect-error TSFIXME userFilters does't exist in PaginationSearchParams
      paginationParams.queryParams.userFilters
    : {
        direct_reports: {
          label: activeUser.fullName,
          value: activeUser.id,
        },
      };

  const [userFilters, setUserFilters] =
    useState<ActiveFilters>(defaultUserFilters);

  // TODO: multifilters: remove this line when FF is enabled for everyone
  const [userFilter, setUserFilter] = useState<UserFilterSegment | null>(
    paginationParams.userFilter !== undefined
      ? paginationParams.userFilter
      : {
          filterLabel: __('Direct reports of'),
          filterType: 'direct_reports',
          label: activeUser.fullName,
          value: activeUser.id,
        }
  );
  // TODO: multifilters: remove this line when FF is enabled for everyone
  const onUserFilterChange = (newUserFilter: UserFilterSegment | null) => {
    setUserFilter(newUserFilter);

    history.replace(
      history.location.pathname +
        '?' +
        queryString.stringify({
          ...paginationParams,
          userFilter: newUserFilter,
          page: 1,
        })
    );
  };

  return (
    <DesignSystem version={2}>
      <PageHeader
        title={__('Objectives follow-up')}
        actions={<ExportButton />}
      />
      <ContentContainer>
        <div className="!mb-4">
          <Text>
            <StrictlySanitizedHtml
              html={__(
                'Only the <b>published</b> objectives of the current period will be visible on this page and in the export.'
              )}
            />
          </Text>
        </div>

        <Level>
          <LevelRight>
            {withUserMultiFilters ? (
              <UserMultiFilters
                onChange={({ userFilters }) => setUserFilters(userFilters)}
                userFilters={userFilters}
              />
            ) : (
              // TODO: multifilters: remove this line when FF is enabled for everyone
              <UserFilter segment={userFilter} onChange={onUserFilterChange} />
            )}
          </LevelRight>
        </Level>

        <UserObjectivesStats
          userFilter={withUserMultiFilters ? undefined : userFilter}
          userFilters={userFilters}
        />
        {withUserMultiFilters ? (
          <UserObjectivesListWithPagination
            additionalQueryParams={{ userFilters }}
          />
        ) : (
          // TODO: multifilters: remove this line AND file when FF is enabled for everyone
          <OldUserObjectivesListWithPagination
            defaultPaginationParams={{ userFilter }}
            fetchPath="user_objective_periods/dashboard"
            paginationType="url"
            userFilterSegment={userFilter}
          />
        )}
      </ContentContainer>
    </DesignSystem>
  );
};

export default ObjectiveDashboard;
