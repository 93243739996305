import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/pagination/types';
import type { PaginatedCollection, TrainingRequest } from 'models';

import can from 'helpers/can';
import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import { approvalStatusLabelWithIcon } from 'helpers/models/trainingRequest';

import { newDataLoader } from 'lib/dataLoader';
import withPagination from 'lib/pagination/withPagination';
import { del, get } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  FetchContainer,
  HamburgerMenu,
  Link,
  MenuItem,
  MenuList,
  SimpleTable,
  Tooltip,
} from 'components';

import RequestManagementModal from 'scenes/components/TrainingRequestManagementModal';
import UserAvatar from 'scenes/components/UserAvatar';
import deleteRequestWithConfirmation from 'scenes/trainings/helpers/deleteRequestWithConfirmation';
import disabledDeletionTooltipText from 'scenes/trainings/helpers/disabledRequestDeletionTooltipText';

import { DataContext } from '.';
import NoRecordState from './NoRecordState';
import NoResultState from './NoResultState';
import SessionCell from './SessionCell';

type Props = {
  currentPeriodSlug: string;
} & WithPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = DataLoaderProvidedProps &
  AfterPaginateProps & {
    trainingRequestCollection: PaginatedCollection<TrainingRequest>;
  };

const TrainingRequestList = ({
  currentPeriodSlug,
  refetchData,
  page,
  countPerPage,
  queryParams,
  setPreviousPageParams,
  setNextPageParams,
  setQueryParams,
  trainingRequestCollection,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();

  const { search, filter, userFilters } = queryParams;
  const { setRefetchRequests, refetchRequests, setRefetchStats } =
    useContext(DataContext);
  const [foregroundRequest, setForegroundRequest] =
    useState<TrainingRequest | null>(null);

  useEffect(() => {
    if (refetchRequests) {
      refetchData();
      setRefetchRequests(false);
    }
  }, [refetchData, refetchRequests, setRefetchRequests]);

  const deleteTrainingRequest = async (request: TrainingRequest) => {
    await dispatch(
      del(`training/periods/${currentPeriodSlug}/requests/${request.id}`)
    );

    setRefetchRequests(true);
    setRefetchStats(true);
  };

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={trainingRequestCollection}
        search={search}
        page={page}
        countPerPage={countPerPage}
        getPreviousPage={setPreviousPageParams}
        getNextPage={setNextPageParams}
        onQueryParamsChange={setQueryParams}
        isFetching={isFetching}
        hasError={hasError}
        totalCountRenderer={(count?: number | null) =>
          n__('%1 training request', '%1 training requests', count || 0)
        }
        renderNoResult={() => <NoResultState />}
        renderNoRecord={() => <NoRecordState />}
        userFilters={userFilters}
        filter={filter}
        filters={[
          {
            param: 'waiting_for_approval',
            label: __('Waiting for approval'),
          },
          { param: 'approved', label: __('Approved') },
          { param: 'refused', label: __('Refused') },
          { param: 'all', label: __('All') },
        ]}
        withSearch
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <SimpleTable
              rows={trainingRequestCollection?.items || []}
              keyFn={trainingRequest => trainingRequest.id}
              columns={[
                {
                  header: __('Request summary'),
                  cell: trainingRequest => (
                    <Link onClick={() => setForegroundRequest(trainingRequest)}>
                      {trainingRequest.title}
                    </Link>
                  ),
                },
                {
                  header: __('Employee'),
                  cell: request => (
                    <UserAvatar user={request.trainee} withJobTitle />
                  ),
                },
                {
                  header: __('Session name'),
                  cell: trainingRequest => {
                    <SessionCell session={trainingRequest.session} />;
                  },
                },
                {
                  header: __('Status'),
                  cell: request =>
                    approvalStatusLabelWithIcon(request.approvalStatus),
                },
                {
                  header: '',
                  isNarrow: true,
                  cell: request => (
                    <HamburgerMenu>
                      <MenuList>
                        <Tooltip
                          content={disabledDeletionTooltipText(request)}
                          enabled={!can({ perform: 'destroy', on: request })}
                        >
                          <MenuItem
                            disabled={!can({ perform: 'destroy', on: request })}
                            onClick={() =>
                              deleteRequestWithConfirmation(
                                request,
                                deleteTrainingRequest
                              )
                            }
                            isDanger
                          >
                            {__('Delete')}
                          </MenuItem>
                        </Tooltip>
                      </MenuList>
                    </HamburgerMenu>
                  ),
                },
              ]}
              className="mb-2"
              rowClassName="test-training-request-row"
            />
          )}
        />
      </DatatableWrapper>
      {!!foregroundRequest && (
        <RequestManagementModal
          trainingRequest={foregroundRequest}
          onClose={params => {
            setForegroundRequest(null);

            if (params?.shouldRefetchData) {
              setRefetchRequests(true);
              setRefetchStats(true);
            }
          }}
          linkedSessionName={foregroundRequest.session?.name || null}
          currentPeriodSlug={foregroundRequest.trainingPeriod.slug}
        />
      )}
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
      currentPeriodSlug,
    }: AfterPaginateProps) =>
      get(`training/periods/${currentPeriodSlug}/requests`, {
        page,
        countPerPage,
        search,
        userFilters,
        filter,
      }),
    hydrate: {
      trainingRequestCollection: {
        items: {
          creator: {},
          trainee: {
            manager: {},
          },
          trainingPeriod: {},
          session: {},
          trainingCourse: {
            organism: {},
          },
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
      currentPeriodSlug,
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        userFilters,
        filter,
        currentPeriodSlug,
      }),
  })
)(TrainingRequestList);
