import type { TrainingRequest } from 'models';

import { __ } from 'helpers/i18n';

const disabledDeletionTooltipText = (request: TrainingRequest) => {
  if (request.approvalStatus === 'approved') {
    return __('You cannot delete an approved request');
  } else if (request.approvalStatus === 'refused') {
    return __('You cannot delete a refused request');
  } else if (request.session) {
    return __('You cannot delete a request linked to a training session');
  }

  return null;
};

export default disabledDeletionTooltipText;
