import { useState } from 'react';

import type {
  AttributesOnlyOrganization,
  CustomFieldValues,
  Locale,
  Team,
  User,
} from 'models';

import { dateWithoutTimeFromDateString } from 'helpers/date';
import { useCurrentOrganization } from 'helpers/hooks';

export type Attributes = {
  firstName: string;
  lastName: string;
  jobTitle: string;
  locale: Locale;
  registrationNumber: string;
  email: string | null;
  workStartDate: Date | null;
  manager: User | null;
  team: Team | null;
  entityId: string | null;
  level: string | null;
  department: string | null;
  service: string | null;
  region: string | null;
  customFieldValues: CustomFieldValues | null;
  username: string | null | undefined;
  useSso: boolean | null | undefined;
  gender: string | null;
  workingTimeRatio: number | null;
};

export type UpdatableKeys = keyof Attributes;
export type Errors = Partial<Record<UpdatableKeys | 'managerId', string>>;

const initializeAttributes = (
  user: User,
  organization: AttributesOnlyOrganization
): Attributes => ({
  email: user.email || '',
  firstName: user.firstName || '',
  lastName: user.lastName || '',
  jobTitle: user.jobTitle || '',
  gender: user.gender,
  workStartDate: user.workStartDate
    ? dateWithoutTimeFromDateString(user.workStartDate)
    : null,
  locale: user.locale || 'en',
  manager: user.manager || null,
  team: user.team || null,
  entityId: user.entity?.id || null,
  registrationNumber: user.registrationNumber || '',
  level: user.level || '',
  department: user.department || '',
  service: user.service || '',
  region: user.region || '',
  useSso: organization.ssoEnabled
    ? user.useSSO === null
      ? true
      : !!user.useSSO
    : undefined,
  username: organization.usernameEnabled ? user.username || '' : undefined,
  customFieldValues: user.activeCustomFieldValues,
  workingTimeRatio: user.workingTimeRatio,
});

const useUserToEditableAttributes = (user: User) => {
  const organization = useCurrentOrganization();

  const [attributes, setAttributes] = useState<Attributes>(
    initializeAttributes(user, organization)
  );

  const [isDirty, setIsDirty] = useState(false);

  const onAttributeChange = (name: UpdatableKeys, value: any) => {
    setAttributes({
      ...attributes,
      [name]: value,
    });
    if (!isDirty) setIsDirty(true);
  };

  const onAttributesReset = () => {
    setAttributes(initializeAttributes(user, organization));
    setIsDirty(false);
  };

  return {
    isDirty,
    attributes,
    onAttributesReset,
    onAttributeChange,
    setIsDirty,
  };
};

export default useUserToEditableAttributes;
