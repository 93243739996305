import React from 'react';

import type { PaginationType } from './types';
import type { PaginationSearchParams } from 'lib/dataLoader/pagination/types';

import { getDisplayName } from 'helpers/hoc';
import invariant from 'helpers/invariant';

import DeprecatedStatePaginationFactory from './DeprecatedStatePaginationFactory';
import UrlPaginationFactory from './UrlPaginationFactory';

export type WithDeprecatedPaginationProps = {
  paginationType: PaginationType;
  defaultPaginationParams?: Partial<PaginationSearchParams>;
};

type Props = WithDeprecatedPaginationProps;

type State = {
  isInitialized: boolean;
};

/**
 * @deprecated This HOC is deprecated and will be removed in future releases.
 * Please use the new pagination HOC instead.
 */

export default function paginationFactory(
  WrappedComponent: React.ComponentType<any>
) {
  class Paginator extends React.Component<Props, State> {
    PaginatedComponent = null;

    state = {
      isInitialized: false,
    };

    componentDidMount() {
      // @ts-ignore: This file is deprecated, let's not invest time in fixing TS errors
      const {
        paginationType,
        defaultPaginationParams,
      }: WithDeprecatedPaginationProps = this.props;

      invariant(
        paginationType === 'state' || paginationType === 'url',
        "Pagination type should be 'state' or 'url'"
      );

      switch (paginationType) {
        case 'state':
          // @ts-ignore: This file is deprecated, let's not invest time in fixing TS errors
          this.PaginatedComponent = DeprecatedStatePaginationFactory({
            defaultPaginationParams: () => defaultPaginationParams,
          })(WrappedComponent);
          break;
        case 'url':
          // @ts-ignore: This file is deprecated, let's not invest time in fixing TS errors
          this.PaginatedComponent = UrlPaginationFactory({
            defaultPaginationParams: () => defaultPaginationParams,
          })(WrappedComponent);
          break;
        default:
          throw new Error("Pagination type should be 'state' or 'url'");
      }

      this.setState({ isInitialized: true });
    }

    render() {
      const { paginationType, defaultPaginationParams, ...otherProps } =
        this.props;
      const PaginatedComponent = this.PaginatedComponent;

      if (!this.state.isInitialized) return null;

      invariant(
        PaginatedComponent,
        'PaginatedComponent is defined once initialized'
      );

      // @ts-ignore: This file is deprecated, let's not invest time in fixing TS errors
      return <PaginatedComponent {...otherProps} />;
    }
  }

  // @ts-ignore: This file is deprecated, let's not invest time in fixing TS errors
  Paginator.displayName = `withPagination(${getDisplayName(WrappedComponent)})`;
  return Paginator;
}
