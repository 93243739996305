import classNames from 'classnames';
import React from 'react';

import Tooltip from 'components/Tooltip';

import { Data } from './types';

type Props = {
  data: Data[];
  isCompact?: boolean;
  visible?: boolean;
  className?: string;
};

const Bar = ({ data, isCompact, visible = true, className }: Props) => {
  const height = isCompact ? 8 : 24;
  return (
    <div
      className={classNames('flex', { invisible: !visible }, className)}
      style={{
        height,
        width: '100%',
        overflow: 'hidden',
        borderRadius: 100,
        backgroundColor: '#eee',
      }}
    >
      {data.map((data, i) => {
        const { size, value, color, tooltipContent } = data;
        const style = { flex: size || value || 0, backgroundColor: color };

        if (tooltipContent) {
          return (
            <Tooltip key={i} triggerStyle={style} content={tooltipContent}>
              <div />
            </Tooltip>
          );
        } else {
          return <div key={i} style={style} />;
        }
      })}
    </div>
  );
};

export default Bar;
