import * as React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { TrainingPeriod } from 'models';
import type { AppDispatch } from 'redux/actions';

import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { requestStarted, requestSucceeded } from 'lib/dataLoader/actions';
import { get } from 'redux/actions/api';

import PopoverPicker from 'scenes/components/TrainingPeriodPicker/PopoverPicker';
import SelectPicker from 'scenes/components/TrainingPeriodPicker/SelectPicker';

export const fakeAllPeriodsTrainingPeriod = () =>
  ({
    id: 'all_periods',
    slug: 'all_periods',
    name: __('All periods'),
  } as TrainingPeriod);

type Props = {
  renderingStyle: 'select' | 'popover';
  currentPeriodSlug: string;
  onChange: undefined | ((period: TrainingPeriod) => void);
  disabled?: boolean;
  allPeriodsOptionAvailable?: boolean;
};

type AfterDataLoaderProps = {
  periods: Array<TrainingPeriod>;
} & Props &
  DataLoaderProvidedProps;

const TrainingPeriodPicker = ({
  renderingStyle,
  currentPeriodSlug,
  periods,
  onChange,
  isFetching,
  hasError,
  disabled,
  allPeriodsOptionAvailable,
}: AfterDataLoaderProps) => {
  const Picker = renderingStyle === 'select' ? SelectPicker : PopoverPicker;

  let currentPeriod: TrainingPeriod | undefined;

  if (!isFetching && !!periods) {
    if (allPeriodsOptionAvailable) {
      periods = [fakeAllPeriodsTrainingPeriod(), ...periods];
    }

    currentPeriod = periods.find(period => period.slug === currentPeriodSlug);
  }

  if (hasError) {
    return (
      <p className="has-text-danger">
        {__('An error has occurred while fetching training periods.')}
      </p>
    );
  }

  return (
    <Picker
      currentPeriod={currentPeriod}
      periods={periods}
      onChange={onChange}
      isFetching={isFetching}
      disabled={disabled}
    />
  );
};

const cacheKey = 'training_period_ids';

export async function refetchTrainingPeriods(dispatch: AppDispatch) {
  await dispatch(requestStarted(cacheKey));
  const response = await dispatch(get('training/periods'));
  await dispatch(requestSucceeded(cacheKey, response.response.body));
}

export default newDataLoader({
  fetch: () => get('training/periods'),
  hydrate: {
    periods: {},
  },
  cacheKey: () => cacheKey,
})(TrainingPeriodPicker) as React.ComponentType<Props>;
