import React, { Fragment, useState } from 'react';

import { __ } from 'helpers/i18n';

import { ContentContainer, PageHeader, PageTitle, SearchBar } from 'components';

import TagPicker from 'scenes/components/TagPicker';

import TrainingRequestModalButton from '../components/TrainingRequestModalButton';
import CourseList from './CourseList';

const Catalog = () => {
  const [selectedTab, setSelectedTab] = useState<'suggestions' | 'trainings'>(
    'suggestions'
  );
  const [search, setSearch] = useState('');
  const [selectedTagLabels, setSelectedTagLabels] = useState<Array<string>>([]);

  const action = <TrainingRequestModalButton trainingCourse={null} />;

  return (
    <Fragment>
      <PageTitle title={[__('Trainings'), __('Catalog')]} />
      <PageHeader title={__('Explore the training catalog')} actions={action} />
      <ContentContainer>
        <div className="flex flex-col">
          <div className="flex md:flex-row md:items-center md:gap-4 flex-col gap-2">
            <div className="md:w-1/3">
              <SearchBar
                value={search}
                onChange={setSearch}
                placeholder={__('Search')}
              />
            </div>
            <TagPicker
              context="training"
              selectedTagLabels={selectedTagLabels}
              className="min-w-[200px]"
              placeholder={__('Tags')}
              onTagsChange={tags =>
                setSelectedTagLabels(tags.map(tag => tag.label))
              }
            />
          </div>
          <CourseList
            search={search}
            tagLabels={selectedTagLabels}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
          />
        </div>
      </ContentContainer>
    </Fragment>
  );
};

export default Catalog;
