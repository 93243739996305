import React, { Fragment, useState } from 'react';

import type { BaseTrainingCourse, Tag, TrainingSession } from 'models';

import { FormErrors } from 'helpers/api';
import can from 'helpers/can';
import compositeKey from 'helpers/compositeKey';
import { useOrganizationSettings } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  formatsTranslation,
  typesTranslation,
} from 'helpers/models/trainingCourse';

import {
  AutoSave,
  Checkbox,
  Column,
  Columns,
  FeatureFlagged,
  Field,
  FieldError,
  IOption,
  Icon,
  Input,
  Label,
  NumberInput,
  Select,
  StrictlySanitizedHtml,
  Text,
  Tooltip,
} from 'components';
import FloatInput from 'components/formElements/advancedElements/FloatInput';

import TagPicker from 'scenes/components/TagPicker';
import TrainingCoursePicker from 'scenes/components/TrainingCoursePicker';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';
import SimpleSelect from 'scenes/trainings/PlanManagement/components/SimpleSelect';

import EndDatePicker from './EndDatePicker';
import StartDatePicker from './StartDatePicker';

type Props = {
  session: TrainingSession;
  setSession: (session: TrainingSession) => void;
  onChange: (session?: TrainingSession) => Promise<void>;
  errors: FormErrors;
};

const EditableFields = ({ session, setSession, onChange, errors }: Props) => {
  const formDisabled = !can({ perform: 'update', on: session });
  const canUpdateLocation = can({
    perform: 'update_dates_and_location',
    on: session,
  });

  const locationDisabledContent =
    canUpdateLocation || formDisabled
      ? null
      : __(
          'Dates and location of the training session cannot be edited once the calendar invitation has been sent'
        );

  const { trainingCatalogEnabled } = useOrganizationSettings();

  const options: IOption<string>[] = [
    {
      value: 'catalog',
      label: __('From the catalog'),
      icon: <Icon name="menu_book" />,
    },
    {
      value: 'offCatalog',
      label: __('Off-catalog'),
      icon: <Icon name="edit" />,
    },
  ];
  const [trainingOrigin, setTrainingOrigin] = useState<IOption<string>>(
    session.trainingCourse === null || !trainingCatalogEnabled
      ? options[1]
      : options[0]
  );

  const handleCourseChange = (trainingCourse?: BaseTrainingCourse) => {
    const newSession = {
      ...session,
      trainingCourse: trainingCourse ? { ...trainingCourse } : null,
      trainingOrganization: trainingCourse?.organism.name || null,
    };
    onChange(newSession);
  };

  const offCatalogInput = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8">
        <Field>
          <Label>{__('Session name')}</Label>
          <Input
            value={session.name || ''}
            placeholder={__('Add training name')}
            onBlur={() => onChange()}
            onChange={name => setSession({ ...session, name })}
            disabled={formDisabled}
            testClassName="test-name-field"
          />
          <FieldError>{errors.name}</FieldError>
        </Field>
        <Field>
          <Label>{__('Training organization')}</Label>
          <Input
            value={session.trainingOrganization || ''}
            onBlur={() => onChange()}
            onChange={trainingOrganization =>
              setSession({
                ...session,
                trainingOrganization,
              })
            }
            disabled={formDisabled}
          />
          <FieldError>{errors.trainingOrganization}</FieldError>
        </Field>
      </div>
    );
  };

  const fromCatalogSearch = () => {
    return (
      <div className="flex flex-col">
        <Columns className="mb-0">
          <Column size={6}>
            <Field>
              <Label>{__('Select a training course')}</Label>{' '}
              <TrainingCoursePicker
                onChange={trainingCourse => {
                  handleCourseChange(trainingCourse);
                }}
                trainingCourse={session.trainingCourse}
                isDisabled={formDisabled}
              />
            </Field>
          </Column>
          <Column size={6}>
            <Field>
              <Label>{__('Training organization')}</Label>
              <Input
                value={session.trainingOrganization || ''}
                disabled={true}
              />
              <FieldError>{errors.trainingOrganization}</FieldError>
            </Field>
          </Column>
        </Columns>

        <Field className="mb-2">
          <Label>{__('Session name')}</Label>
          <Input
            value={session.name || ''}
            onBlur={() => onChange()}
            onChange={name => {
              setSession({ ...session, name });
            }}
            disabled={formDisabled}
            placeholder={__('Enter training session name')}
            testClassName="test-name-field"
          />
          <FieldError>{errors.trainingCourse || errors.name}</FieldError>
        </Field>
      </div>
    );
  };

  return (
    <Fragment>
      {trainingCatalogEnabled ? (
        <Fragment>
          <Field className="mb-4">
            <Label>{__('Origin of training')}</Label>
            <Select
              value={trainingOrigin}
              options={options}
              placeholder={__('Select training origin')}
              onChange={option => {
                setTrainingOrigin(option!);
                onChange({
                  ...session,
                  trainingCourse: null,
                });
              }}
              isDisabled={formDisabled}
            />
            <FieldError>{errors.trainingType}</FieldError>
          </Field>
          {trainingOrigin.value === 'catalog' && fromCatalogSearch()}
          {trainingOrigin.value === 'offCatalog' && offCatalogInput()}
        </Fragment>
      ) : (
        offCatalogInput()
      )}

      <div className="mt-4">
        <Field>
          <Checkbox
            className="mr-4"
            isChecked={!!session.certifying}
            disabled={formDisabled}
            onChange={() =>
              onChange({ ...session, certifying: !session.certifying })
            }
            label={__('This training leads to certification')}
          />
        </Field>
      </div>
      <Columns className="mt-0 mb-0">
        <Column size={6}>
          <StartDatePicker
            session={session}
            onChange={onChange}
            errors={errors}
            disabled={formDisabled}
          />
        </Column>
        <Column size={6}>
          <EndDatePicker
            session={session}
            onChange={onChange}
            errors={errors}
            disabled={formDisabled}
          />
        </Column>
      </Columns>

      <Columns className="mt-0 mb-0">
        <Column size={6} className="pt-0">
          <Field>
            <Label>{__('Number of hours')}</Label>
            <FloatInput
              syncWithParent={true}
              value={
                session.durationInHours ? Number(session.durationInHours) : null
              }
              placeholder={__('Enter the number of hours')}
              onInputChange={(durationInHours: number | null) => {
                onChange({
                  ...session,
                  durationInHours: `${durationInHours || ''}`,
                });
              }}
              isDisabled={formDisabled}
              saveOnlyOnBlur
            />
            <FieldError>{errors.durationInHours}</FieldError>
          </Field>
        </Column>
        <Column size={6} className="pt-0">
          <Field>
            <Label>{__('Number of available seats')}</Label>
            <div className="grid grid-cols-2 gap-x-8">
              <div className="flex items-center">
                <Text className="mr-2"> {__('Minimum')}</Text>
                <NumberInput
                  style={{ MozAppearance: 'textfield' }}
                  value={
                    session.minAvailableSeats === null
                      ? undefined
                      : session.minAvailableSeats
                  }
                  onChange={value =>
                    setSession({
                      ...session,
                      minAvailableSeats: value || null,
                    })
                  }
                  disabled={formDisabled}
                  onBlur={() => onChange()}
                />
              </div>
              <div className="flex items-center">
                <Text className="mr-2"> {__('Maximum')}</Text>
                <NumberInput
                  style={{ MozAppearance: 'textfield' }}
                  value={
                    session.maxAvailableSeats === null
                      ? undefined
                      : session.maxAvailableSeats
                  }
                  onChange={value =>
                    setSession({
                      ...session,
                      maxAvailableSeats: value || null,
                    })
                  }
                  disabled={formDisabled}
                  onBlur={() => onChange()}
                />
              </div>
            </div>
            <FieldError>{errors.minAvailableSeats}</FieldError>
            <FieldError>{errors.maxAvailableSeats}</FieldError>
          </Field>
        </Column>
      </Columns>
      <Columns className="mt-0 mb-0">
        <Column size={6} className="pt-0">
          <Field>
            <Label>{__('Type')}</Label>
            <SimpleSelect
              value={session.trainingType}
              values={typesTranslation()}
              placeholder={__('Select a type')}
              onChange={trainingType => {
                onChange({
                  ...session,
                  trainingType,
                });
              }}
              isDisabled={formDisabled}
            />
            <FieldError>{errors.trainingType}</FieldError>
          </Field>
        </Column>
        <Column size={6} className="pt-0">
          <Field>
            <Label>{__('Format')}</Label>
            <SimpleSelect
              value={session.trainingFormat}
              values={formatsTranslation()}
              placeholder={__('Select a format')}
              onChange={trainingFormat => {
                onChange({
                  ...session,
                  trainingFormat,
                });
              }}
              isDisabled={formDisabled}
            />
            <FieldError>{errors.trainingFormat}</FieldError>
          </Field>
        </Column>
      </Columns>

      <Field>
        <Label>{__('Location of the training')}</Label>
        <Tooltip content={locationDisabledContent}>
          <Input
            value={session.location || ''}
            onBlur={() => onChange()}
            onChange={location => {
              setSession({
                ...session,
                location,
              });
            }}
            disabled={formDisabled || !canUpdateLocation}
          />
        </Tooltip>
        <FieldError>{errors.location}</FieldError>
      </Field>
      <div className="grid mt-4 grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8">
        <Field>
          <Label>{__('Period')}</Label>
          <TrainingPeriodPicker
            renderingStyle="select"
            currentPeriodSlug={session.period.slug}
            disabled={formDisabled}
            onChange={trainingPeriod =>
              onChange({ ...session, period: trainingPeriod })
            }
          />
        </Field>
      </div>
      <FeatureFlagged flag="trainingTags">
        <div className="mt-4">
          <Field>
            <Label labelClassName="mb-0">
              {__('Tags (training category or area)')}
            </Label>
            {trainingCatalogEnabled && (
              <Text preset="13s7" color="soften" className="block mb-2">
                <StrictlySanitizedHtml
                  html={__(
                    'They will be <b>visible to employees and managers</b> and used as <b>filter in the training catalog.</b>'
                  )}
                />
              </Text>
            )}
            <AutoSave
              onChange={(tags: Tag[]) => setSession({ ...session, tags })}
              doPersist={onChange}
              fieldUid={compositeKey({
                trainingSessionId: session.id,
                type: 'training_session_tags',
              })}
              render={onChange => (
                <TagPicker
                  context="training"
                  onTagsChange={onChange}
                  selectedTags={session.tags || []}
                  disabled={formDisabled}
                  placeholder={
                    formDisabled ? __('No tags') : __('Select or create tags')
                  }
                  menuPlacement="top"
                />
              )}
            />
            <FieldError>{errors.tagId}</FieldError>
          </Field>
        </div>
      </FeatureFlagged>
    </Fragment>
  );
};

export default EditableFields;
