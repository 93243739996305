import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

type Props = {
  userFullName: string;
};

const NoResultState = ({ userFullName }: Props) => {
  return (
    <EmptyStateWithIcon
      className="mb-5"
      inBox={false}
      iconName="edit_note"
      title={__(
        'There is no training requests for %1 matching your search.',
        userFullName
      )}
    />
  );
};

export default NoResultState;
