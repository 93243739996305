import React, { Fragment } from 'react';
import { compose } from 'redux';

import type { DeprecatedPaginationProps } from 'lib/dataLoader/pagination/types';

import {
  Objective,
  ObjectiveVersion as ObjectiveVersionType,
  PaginatedCollection,
} from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import withDeprecatedStatePagination from 'lib/dataLoader/pagination/DeprecatedStatePaginationFactory';
import { get } from 'redux/actions/api';

import { InfiniteFetchContainer } from 'components';
import { EmptyStateWithIcon } from 'components/emptyStates';

import KeyResultVersion from './KeyResultVersion';
import ObjectiveVersion from './ObjectiveVersion';

type Props = {
  objective: Objective;
};

type AfterPaginateProps = Props & DeprecatedPaginationProps;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    versionsCollection: PaginatedCollection<ObjectiveVersionType>;
  };

const ObjectiveVersionsEmptyState = () => {
  return (
    <EmptyStateWithIcon
      iconName="history"
      title={__('This objective has no history')}
      description={__(
        'It was created before the implementation of the objective history on Elevo'
      )}
      inBox={false}
    />
  );
};

const ObjectiveVersions = ({
  versionsCollection,
  isFetching,
  hasError,
  getNextPage,
}: AfterDataLoaderProps) => {
  return (
    <InfiniteFetchContainer
      collection={versionsCollection}
      getNextPage={getNextPage}
      isFetching={isFetching}
      hasError={hasError}
      render={items => {
        if (items.length === 0) {
          return <ObjectiveVersionsEmptyState />;
        }

        return (
          <Fragment>
            {items.map(version => (
              <Fragment key={version.id}>
                {version.itemType === 'Objective' && (
                  <ObjectiveVersion key={version.id} version={version} />
                )}

                {version.itemType === 'BaseKeyResult' && (
                  <KeyResultVersion key={version.id} version={version} />
                )}
              </Fragment>
            ))}
          </Fragment>
        );
      }}
    />
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedStatePagination({}),
  newDataLoader({
    fetch: ({ objective, page, countPerPage }: AfterPaginateProps) =>
      get(`/objectives/${objective.id}/versions`, {
        page,
        countPerPage,
      }),
    hydrate: {
      versionsCollection: {
        items: {
          user: {},
        },
      },
    },
    cacheKey: ({ objective, page, countPerPage }: AfterPaginateProps) =>
      compositeKey({
        type: 'version',
        page,
        countPerPage,
        objective,
      }),
  })
)(ObjectiveVersions);
